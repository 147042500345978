<template lang="html">
    <div id="password-page" class=" dynamic-page" >
        <h3 v-html="$t('386')"></h3>
        <form-component v-if="type !== 'passwordReset'"  :form="emailForm" v-on:submitForm="sendEmail"></form-component>
    </div>
</template>

<script src="./passwordPage-component.js"></script>


