import formComponent from '@components/form/form-component.vue';
import UserService from '@services/UserService';

export default {
    name: 'passwordResetPageComponent',
    components: {
        formComponent
    },
    props: [
        'cordovaIsActive'
    ],
    data() {
        return {
            type: this.$root.urlParams.pageType,
            emailForm: {
                inputs: [{
                    name: "login",
                    label: this.$t('377'),
                    placeholder: this.$t('181'),
                    type: "email",
                    showRequired: true,
                    required: true,
                    style: 'input',
                    visible: true
                }],
                buttons: [{
                    text: this.$t('387'),
                    id: "form-submit",
                    class: "button-submit button-round button-style-1 button-blue",
                    visible: true,
                    disabled: true
                }],
                mustDisableOnSubmit : true
            }

        }
    },
    destroyed: function () {
        this.$store.commit('hideAlert');
    },
    methods: {
        /**
         * @description: send email when submitting reset request
         * @param {object} formDatas receied datas from form component
         */
        sendEmail: function (formDatas) {
            console.log('sendEmail', formDatas)
            if (formDatas.login !== undefined) {
                this.$store.commit('showAlert', 'wait');

                UserService.recoverPassword(formDatas.login)
                    .then((userLogin) => {
                        this.$store.commit('showAlert', {
                            confirm: this.$t('83') + userLogin
                        });
                    })
                    .catch(error => {
                        this.$store.commit('showAlert', {
                            error: error
                        });
                    })

            } else {
                this.$store.commit('showAlert', {
                    error: 'erreur'
                });
            }
        }
    }
}