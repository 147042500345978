import axios from 'axios';
import Storage from '../classes/storage.class';
import Tools from '@/js/utils/tools';
import store from '../store/store';
import FilesService from './FilesService';

/**
 * @description tools for user management
 * @external {axios} https://github.com/axios/axios
 */
let UserService = {
    isLogged: false,
    storageName: "Salvum:user",
    storage: undefined,
    urls: undefined,
    alreadyLogged: false,
    wantResetPassword: false,
    wantConfirm: false,
    activationCode: undefined,
    userModel: {
        login: undefined,
        password: undefined,
        unencrypted : undefined, 
        token: undefined,
        loginApi: undefined,
        name: undefined,
        birthDate: undefined,
        birthCity: undefined,
        department: undefined,
        group: undefined,
        lastName: undefined,
        apiId: undefined,
        imageUrl: undefined,
        email: undefined,
        gender: undefined,
        newsletter: 0,
        //registerQuestion: undefined,
        cgvu: undefined,
        account: undefined,
        country: undefined,
        promocode: undefined,
        promocodeid: undefined,
        uniq: 0,
        city: undefined,
        basket: [],
        trainings: {},
        numMember : undefined,
        phone : undefined,
    },
    actualUser: {},


    /************ INITIALIZATION *************/

    /**
     * @access public
     * @param {string} getUserUrl
     */
    init(config) {
        //console.log('** USER SERVICE : init with urls ', config.urls)
        this.urls = config.urls;

    },


    /************ DATABASE RELATIONS *************/


    /**
     * @access public
     * @param {object} userDatas  datas to check on server
     */
    checkIfUserExistInDB(userDatas) {

        return new Promise((resolve, reject) => {
            console.log('** USER SERVICE : check if user exist in DB ', userDatas, this.urls.login);
            axios.post(this.urls.login, userDatas)
                .then(response => {
                    let data = response.data
                   // console.log('** USER SERVICE check reponse:', data)
                    if(data.status == 0){
                    console.warn('** USER SERVICE :check if user exist error', data.msg)
                    reject(data.msg);
                    } else if(data.status == 1){
                        //console.log('user exist', data.user)
                        resolve(data.user);
                    }
                })
                .catch(error => {
                    console.warn('** USER SERVICE : user check not possible ', error)
                    reject(error)
                })
        })
    },

    /**
     * @access public
     * @param {object} userLogin  login of user to check
     * resolve if user is Available
     * rejetc is user is not available
     */

    checkIfLoginIsAvailable(userLogin) {
        return new Promise((resolve, reject) => {
           // console.log('** USER SERVICE : check if user is available', userLogin);
            axios.post(this.urls.isLoginAvailable, {
                    login: userLogin
                })
                .then(response => {
                    //console.log('** USER SERVICE response:', response)
                    if (response.data.status === 1) {
                        resolve(response.data);
                    } else if(response.data.status == 0){
                        reject(response.data.msg);
                    } else {
                        reject()
                    }

                })
                .catch(error => {
                    console.warn('** USER SERVICE : user login check not possible ', error)
                    reject(error)
                })
        })
    },

   

    checkIfAuthorized(userId, userToken) {


        return new Promise((resolve, reject) => {
            console.log('** USER SERVICE : check if user is Authorized ', userId, userToken);
            axios.post(this.urls.checkToken, {
                    id: userId
                }, {
                    headers: {
                        'x-access-token': userToken
                    }
                })
                .then(response => {
                    console.log('** USER SERVICE checkauthorized response:', response.data)
                    //console.log('** USER SERVICE check reponse error:', response.data.error)
                    if (response.data.status === 0) {
                        console.warn('**USER SERVICE ** user not authorized')
                        reject(response.data.status);
                    } else if (response.data.status === 1) {
                        console.log('**USER SERVICE ** user  authorized')
                        resolve();

                    }

                })
                .catch(error => {
                    //console.log('** USER SERVICE : user check not possible ', error)
                    reject(error)
                })
        })

    },


    /**
     * @description send email to server to register user
     * @param {object} userDatas datas of user that contains email
     */
    registerUser(userDatas) {
        return new Promise((resolve, reject) => {
            console.log('** USER SERVICE : register user  ', userDatas);

            axios.post(
                    this.urls.register,
                    userDatas
                )
                .then(response => {
                    console.log(response.data)
                     if (response.data.status == 0) {
                        console.warn('** USER SERVICE : user registration not authorized ', response.data.msg);
                        reject(response.data.msg);
                    } else if (response.data.status == 1 && response.data.user){
                            //console.log('** USER SERVICE : user registration succeed ', response.data);
                            resolve(response.data.user)
                        
                    } else {
                        reject(response.data.msg);
                    }


                })
                .catch(error => {
                    console.warn('** USER SERVICE : user registration failed ', error);
                    reject(error)
                })
        })
    },

    confirmUser(userDatas, activationCode) {
        return new Promise((resolve, reject) => {
            console.log('** USER SERVICE : confirm user  ', this.urls.confirmation, userDatas);

            userDatas.activationCode = activationCode;
            
            //console.log('datas with user code' , userDatas)
            axios.post(
                    this.urls.confirmation,
                    userDatas
                )
                .then(response => {
                    //console.log(response.data)
                    if (response.data.status == 0) {
                        console.warn('** USER SERVICE : user confirmation not authorized ', response.data.msg);
                        reject(response.data.msg);
                    } else if (response.data.status == 1 && response.data.user){
                        //console.log('** USER SERVICE : user confirmation succeed ', response.data);
                        resolve(response.data.user)
                    
                } else {
                    reject(response.data.msg);
                }


                })
                .catch(error => {
                    console.warn('** USER SERVICE : user confirmation failed ', error);
                    reject(error)
                })
        })
    },

    resetUserPassword(datas, userCode, clearPassword) {
        return new Promise((resolve, reject) => {
            console.log('** USER SERVICE : reset password for user  ' + userCode + " with password " + datas.password + '/ ' + datas.unencrypted + ' on url ' + this.urls.resetPassword);

            axios.post(
                    this.urls.resetPassword, {
                        tempCode: userCode,
                        newPassword: datas.password,
                        unencrypted : datas.unencrypted
                    }
                )
                .then(response => {
                   // console.log(response.data)
                    if (response.data.status ===1) {
                        //console.log('** USER SERVICE : user confirmation succeed ', response.data);
                        resolve(response.data.user)
                    } else if (response.data.status === 0) {
                        //console.log('** USER SERVICE : user confirmation error', response.data.error);
                        reject(response.data.msg);
                    }


                })
                .catch(error => {
                    console.warn('** USER SERVICE : user confirmation failed ', error);
                    reject(error)
                })
        })
    },

    recoverPassword(login) {
        return new Promise((resolve, reject) => {
            console.log('** USER SERVICE : recover password for user  ' + login + ' to ' + this.urls.recoverPassword);

            axios.post(
                    this.urls.recoverPassword, {
                        login: login
                    }
                )
                .then(response => {
                    if (response.data.status === 1) {
                        //console.log('** USER SERVICE : user recover password succeed ', response.data);
                        resolve(login)
                    } else if (response.data.status === 0) {
                        console.warn('** USER SERVICE : user recover password error', response.data.msg);
                        reject(response.data.msg);
                    }


                })
                .catch(error => {
                    console.warn('** USER SERVICE : recover password failed ', error);
                    reject(error)
                })
        })
    },

    
    updateLocalUser(user) {
        return new Promise((res, rej) => {
            //console.log('update local user with user', user);
            store.commit('setActualUser', user);
            res(user);
        })
    },
    
    getFormattedUser(userDatas) {
        //console.log('USER SERVICE : format user ', userDatas, 'with user model ', this.userModel)
        let formattedUser = {};

        for (var prop in this.userModel) {
            //console.log(prop)
            //console.log(userDatas[prop])
            formattedUser[prop] = userDatas[prop] !== undefined ? userDatas[prop] : this.userModel[prop];
        }


        //console.log('** USER SERVICE : formatted user ', formattedUser)

        return formattedUser;

    },
   

    getDefaultAvatar(avatars, gender = undefined) {
        //console.log('** USER SERVICE : get default avatar in ', avatars + ' with default gender ', gender)

        let userGender = gender !== undefined ? gender : store.getters.getActualUser.gender;

        for (let avatar of avatars) {
            //console.log(avatar);
            if (avatar.gender === userGender) {
                //console.log('set this avatar ', avatar.image)
                return avatar.image;
            }
        }
    },



    getAccount(accountId) {
        //console.log('**USER SERVICE get account for id ', accountId, store.getters.getDatas.accounts)

        if (accountId == -1) {
            //console.log('no account type')
            return undefined;
        } else {
            //console.log('account type exist ')
            for (let index in store.getters.getDatas.accounts) {

                if (accountId == store.getters.getDatas.accounts[index].id) {
                    //console.log(store.getters.getDatas.accounts[index])
                    return store.getters.getDatas.accounts[index]
                }
            }
        }

    }

}

export default UserService;