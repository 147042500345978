/**
 * GLOBAL MANAGEMENT OF STATES
 */




import userStore from './user-store';
import { createStore } from 'vuex'



const store = createStore({

    modules: {
        user: userStore
    },
    state: {
        actualPage: undefined,     //actual page displayed
        actualLang : undefined,
        isAuth : false,
        avatars : undefined, 
        datas : {},
        alertMessage : undefined,
        showModal : false,
        showCgu : false,
        showModalParams : undefined,
    },
    getters: {
        /*getActualPage: state => {
            return state.actualPage;
        },*/
       
        getDatas : state => {
            //console.log('__STORE get datas ', state.datas)
            return state.datas;
        },
        getAvatars : state => {
            //console.log('__STORE get avatars ', state.avatars)
            return state.avatars;
        },
        getAlertMessage : state => {
           
            return state.alertMessage;
        },
        getShowModal : state => {
           
            return state.showModal;
        },
        getShowModalParams : state => {
           
            return state.showModalParams;
        },
        getShowCgu :  state => {
            return state.showCgu;
        },
     
     
    },
    mutations: {
        /*setActualPage(state, page) {
            //console.log('__STORE : set actual page :', page)
            state.actualPage = page;
        },*/
       showAlert(state, message){
        state.alertMessage = message;
         console.log('__STORE : set alert message : ', message);
       },
       hideAlert(state){
        //console.log('__STORE : hide alert : ');
           state.alertMessage = undefined;
       },
        setDatas(state, datas) {
           
            state.datas = datas;
            //console.log('__STORE : set datas : ', datas);
        },
        setActualLang(state, lang){
            //console.log('__STORE : set lang : ', lang)
            state.actualLang = lang;
            App.vue.$i18n.locale = lang;
        },
        setAvatars (state, avatars){
            state.avatars = avatars;
            //console.log('__STORE : set avatars : ', state.avatars)
        },
        showModal(state, params){
            state.showModal = true;
            state.showModalParams =params;
        },
        hideModal(state){
            state.showModal = false;
            state.showModalParams = undefined;
        },
        showCgu(state,  value){
            state.showCgu = value;
        },
        
    }
})

export default store;