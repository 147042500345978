<template lang="html">
  <div :class="'form-input form-input-text ' +  ' '+hasIcon +' '+ className + ' '+ (inputValue.length > 0 ? (isValid ? 'valid' : 'invalid') : '') " :ref="input.name" :id="'input-'+input.name">
   
      
    <div :class="'form-input-content '+(focus ? 'focus' : 'no-focus')">
      <label :for="index !==undefined ? 'form-'+index : undefined" v-if="input.label !== undefined && input.label !== ''">{{input.label}}<!--span class="input-required" v-if="input.required">*</span--></label>
      <p class="form-input-info" v-if="input.info !== undefined">{{input.info}}</p>      
      <p  v-if="alertDisplayed" class="input-alert ">{{input.alert}}</p>
      <input  @click="deletePlaceholder" autocapitalize="off"  @focusin="onFocus(true)" @focusout="onFocus(false)" :autocomplete="input.autocomplete" :id="index !==undefined ? 'form-'+index : undefined" :disabled="input.disabled" :required="input.required"  :name="input.name" :placeholder="placeholder" type="text"  v-model="inputValue" v-on:input="validInput">
    
    </div>
  </div>
</template>

<script src="./input-text-component.js"></script>

<style scoped src="./input-text-component.css"></style>

