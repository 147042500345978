/**
 * @example <modal-component  :content="modalContent" @closemodal="$store.commit('resetModal')"></modal-component>

 * emit 'closeModal' when modal is closed. Can be used to reset store content
 * 
 * To use it: 
 * this.$store.commit('setModal', content);
 *  
 * */


export default {
	name: "modalComponent",
	components: {
	},
	props: ["content", "type"],
	data() {
		return {};
	},
	computed: {},

	mounted() {
		console.log("modal cgu ", this.$root.config.serverConfig.cgvuContent);
	},
	destroyed() {},
	methods: {
		/**
		 * when clicking on an element
		 * @param {object} evt DOM element clicked on
		 */
		onClick(evt) {
			console.log(evt.target.id, this.$store.getters.getShowCgu);
			if (evt.target.id == "modal-component" && evt.target.id !== "modal-view") {
				this.$store.commit("showCgu", false);
			}
		},
	},
};
