/**
 * @example <modal-component  :content="modalContent" @closemodal="$store.commit('resetModal')"></modal-component>

 * emit 'closeModal' when modal is closed. Can be used to reset store content
 * 
 * To use it: 
 * this.$store.commit('setModal', content);
 *  
 * */

import buttonComponent from '../button/button-component.vue';

export default {
    name: 'modalComponent',
    components: {
        buttonComponent
    },
    props: [
       
    ],
    computed: {
      params :function(){
          return this.$store.getters.getShowModalParams
      }
      
    },
    mounted(){
        console.log('modal params ', this.params)
    },
    emits:['closemodal'],
    methods: {
        /**
         * when clicking on an element
         * @param {object} evt DOM element clicked on
         */
        onClick(evt) {
            //console.log(evt.target.id, evt.target.id)
            if (evt.target.id === 'modal-component') {
                this.$emit('closemodal');
            }
        },
        onYes(evt) {
            this.$emit('closemodal', {hasApp : true, cb : this.params.onYes});
            
        },
        onNo(evt) {
            this.$emit('closemodal', {hasApp : false, cb : this.params.onNo})
            
        }
    }
}