<template>
<div class="practical-session">

    <div class="practical-session-main-infos">
        <div>
            <h3>{{session.title}}</h3>
            <p class="session-date">{{session.date}}</p>
        </div>

        <div>
			<h3>{{session.city}}</h3>
            <p>{{session.time}}</p>
            <p>
                <span :class="{striked : hasDiscountDisplay }">{{ session.amount / 100 +'€'}}</span>
                &nbsp;&nbsp;
                <span v-if="isFree">{{ $t('641')}}*</span>
                <span v-else-if="hasDiscountDisplay">{{priceToPay + '€'}}*</span>
            </p>
            <p v-if="hasDiscountDisplay" class="note italic tarif">*{{$t('640')}}&nbsp;{{organization.organizationname || ''}}</p>
        </div>
    </div>
   
   

</div>
</template>

<script>
export default {
    name: "practicalSession",
    props: ['session', 'priceToPay', 'discount','organization'],
    data() {
        return {

        }
    },
	computed : {
		isFree(){
			console.log('is free ? ', this.priceToPay == 0)
			return this.priceToPay == 0
		},
		hasDiscountDisplay(){
			return (this.discount && this.priceToPay > 0) || this.isFree
		}
	},
	mounted(){
		console.log('practical-session.vue componentn mounted ', this.organization,  this.session,this.isFree, this.discount, this.hasDiscountDisplay)
	},
    methods : {
			
		
	}
}
</script>

<style lang="scss">
.practical-session {
		margin: 2rem 0;
		border: 1px solid #dbdbdb;
		border-radius: 10px;
		background-color: rgba(45, 38, 92, 0.06);
		padding: 2rem;
		width: calc(100% - 4rem);
		h3 {
			font-size: 24px;
			margin: 0 ;
			line-height: normal;
			font-weight: 600;
		}

		.practical-session-main-infos {
			display: flex;
				flex-direction: row;
			align-items: stretch;

			& > * {
				width: 50%;
				max-width: 50%;
			}

			p {
				margin-bottom: 0;
			}

			& > p {
				font-size: 18px;
				font-weight: 600;
				line-height: 22px;
				padding-right: 2rem;
			}
			& > div {
				display: flex;
				flex-direction: column;
				justify-content: start;
				align-items: flex-start;
			}
			& > div:nth-child(1) {
				padding-right: 2rem;
				border-right: 1px solid #dbdbdb;
			}
			& > div:nth-child(2) {
				padding-left: 2rem;
				p {
					line-height: 1.5;
				}
			}
		}

		.striked {
		text-decoration: line-through;
		font-size: 14px;
	}
	.italic {
		font-style: italic;
	}
	p.tarif {
		font-size: 10px;
	}

	
	}



	@media screen and (min-width: 992px){
		.practical-session {
			p {
				font-size: 16px;
				margin-bottom: 1rem;
				line-height: 24px;
				&.tarif {
					font-size: 12px;
				}
			}
		}
	}

</style>
