/**
 * @example <message-viewer-component  :message="alertMessage"></message-viewer-component>
 * alertMessage must be {
 *  type : message
 * } 
 * with type = 'error' || 'confirm' || 'alert'
 * for waiter display, alertMessage = 'wait';
 * 
 */

import { Spinner } from 'spin.js'
import {CordovaService} from 'salvum-utilities';

export default {
    name: 'messageViewerComponent',
    props: [
        'message'
    ],
    data () {
        return {
            
            displayedMessage: {
                error: undefined,
                alert: undefined,
                confirm: undefined
            },
            closeIcon: this.$root.imgUrl + 'icon-close.svg',
            show : false,
            displayDelay : 5000,
            showWait: false,
            waiterOptions: {
                lines: 10, // The number of lines to draw
                length: 40, // The length of each line
                width: 6, // The line thickness
                radius: 36, // The radius of the inner circle
                scale: 0.4, // Scales overall size of the spinner
                corners: 1, // Corner roundness (0..1)
                color: '#FFF', // CSS color or array of colors
                fadeColor: 'transparent', // CSS color or array of colors
                opacity: 0.25, // Opacity of the lines
                rotate: 0, // The rotation offset
                direction: 1, // 1: clockwise, -1: counterclockwise
                speed: 0.9, // Rounds per second
                trail: 45, // Afterglow percentage
                fps: 20, // Frames per second when using setTimeout() as a fallback in IE 9
                zIndex: 2e9, // The z-index (defaults to 2000000000)
                className: 'spinner', // The CSS class to assign to the spinner
                top: '50%', // Top position relative to parent
                left: '50%', // Left position relative to parent
                shadow: 'none', // Box-shadow for the lines
                position: 'absolute' // Element positioning
            },
            waiter: undefined
        }
    },
    
    watch: {
        message: function () {
            //console.log('message has changed', this.message)
            if (this.message !== undefined) {
                if (this.message === "wait") {
                    this.resetMessage();
                    this.showWaiter();
                } else {
                    this.hideWaiter();
                    this.displayAlert()
                }
            } else {
                this.resetMessage();
            }
        }
    },
    destroyed : function(){
        //console.log('message viewer destroyed ')
        this.resetMessage();
    },
    methods: {
        showWaiter() {
            //console.log('show waiter')
            this.waiter = new Spinner(this.waiterOptions).spin(document.getElementById('message-spinner'));
            this.showWait = true;
            
        },


        hideWaiter() {
            //console.log('hide waiter')
            this.waiter = undefined;
            this.showWait = false;
            
        },


        resetMessage() {
            this.hideWaiter();
            //console.log('reset message')
            this.displayedMessage = {
                error: undefined,
                alert: undefined,
                confirm: undefined
            };
            this.show = false;
           
         
        },


        displayAlert() {
            //console.log('display alert ', this.message)
            if(this.message === undefined){
                this.show = false;
            }
            else if (this.message.confirm !== undefined) {
                //console.log('display alert confirm', this.message.confirm)
                this.displayedMessage = { 'confirm': this.message.confirm };
                this.show = true;
            }
            else if (this.message.alert !== undefined) {
                if (CordovaService.isActive) {
                    CordovaService.displayAlert(this.message.alert);
                } else {
                    alert(this.message.alert);
                }
            }
            else if (this.message.error.message !== undefined) {
                this.displayedMessage = { 'error': this.message.error.message };
                this.show = true;
            }
            else if (this.message.error !== undefined) {
                this.displayedMessage = { 'error': this.message.error };
                this.show = true;
            }
           
            
            
            setTimeout(()=>{
                //this.resetMessage();
            }, this.displayDelay)
        }
    }
}