import captchaComponent from './captcha/captcha-component.vue';
import buttonComponent from '../others/button/button-component.vue';
import inputTextComponent from './input-text/input-text-component.vue';
import inputEmailComponent from './input-email/input-email-component.vue';
import inputPasswordComponent from './input-password/input-password-component.vue';
import inputCheckboxComponent from './input-checkbox/input-checkbox-component.vue';
import inputDateComponent from './input-date/input-date-component.vue';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

/**
 * @description basic form component.
 * @example <form-component   :form="form" v-on:submitForm="checkIfUserExist" v-on:forgotPassword="resetPassword"   ></form-component>
 * @param {array} form - datas of form. Must be as : 
    form : {
                hasCaptcha : false,
                texts : {
                    submit : "Valider",
                forgotPassword : "Mot de passe oublié ?"
                },
                inputs : [
                    {
                        name : "login",
                        placeholder : "Votre identifiant",
                        type : "text",
                        required : 'true',
                        isValid : false
                    },
                    {
                        name : "mdp",
                        placeholder : "Votre mot de passe",
                        type : "password",
                        required : 'true',
                        isValid : false
                    }
                ], 
                buttons : [
                    {
                        
                        text : "Valider",
                        class: "submit",
                        id : "form-submit",
                        disabled : true
                        
                    },
                    {
                        text : "Mot de passe oublié ?",
                        class: "forgot-password",
                        id : "form-forgot-password"
                    }
                ]
            }
           
        }
    WARNING : id for buttons must be : form-submit, form-forgot-password
* @param {bool} mustEmitChange : if form must emit change event when a data is changed (useful to keep datas in temp page)
* @event submitForm can be listen from external, called when submitting form 
* @event forgotPassword can be listen from external, called when click on forgot password button
* @event changeValue can be listen from external, called when a vue is change, if parameter mustEmitChange is true. return name of input and value.
 */
export default {
    name: 'loginFormComponent',
    components: {
        captchaComponent,
        buttonComponent,
        inputTextComponent,
        inputEmailComponent,
        inputPasswordComponent,
        inputCheckboxComponent,
        inputDateComponent,
        MazPhoneNumberInput
        //VuePhoneNumberInput
    },
    props: [
        'form', 'mustEmitChange', 'mustDisplayValidAlert'
    ],
    data () {
        return {
            hasValidCaptcha: this.form.hasCaptcha ? false : true,
            inputsValidNum: 0,
            inputsRequiredNum: 0,
            inputsVisibleNum: 0,
            datasToSend: {
                loginApi: 'form'
            },
            showCaptchaAlert: false,
            phone : {
                translations : {
                    countrySelector: {
                        placeholder: null,
                        error: 'Choisir un pays',
                        searchPlaceholder: null,
                      },
                      phoneInput: {
                        placeholder: '',
                        example: '',
                      },
                },
                preferredCountries: ['FR'],
                onlyCountries : ['FR'],
                placeholder :  this.$t("659"),
                
            },
            
			phoneObject: {
                formattedNumber : '',
                isValid : false
            },
            focusPhone : false,
            isSubmitted : false,
        }
    },
    mounted: function () {
       // console.log('form mounted', this.form)
        
        this.initDatasToSend();
        this.initNumberOfRequiredInputs();
        this.initPhone();
        //console.log('V -- FORM component mounted  ', this.inputsValidNum, this.inputsRequiredNum)
    },
    updated : function(){
        //console.log('form updated with this all valid ', this.isAllValid)
    },
    emits: ['changeValue', 'submitForm'],
    computed: {
        isAllValid: function () {
            //console.log('FORM COMPONENT : IS ALL VALID number of fields valids : ', this.inputsValidNum, '/', this.inputsRequiredNum)
            //console.log('FORM COMPONENT : hasCaptcha : ', this.form.hasCaptcha, this.hasValidCaptcha)
           // console.log('FORM COMPONENT : is all valid ? ', (this.inputsValidNum === this.inputsRequiredNum) && this.hasValidCaptcha)
           // console.log('Must display alert ', ((this.inputsValidNum === this.inputsRequiredNum) && this.form.hasCaptcha && !this.hasValidCaptcha))
            if ((this.inputsValidNum === this.inputsRequiredNum) && this.form.hasCaptcha && !this.hasValidCaptcha) {
                this.showCaptchaAlert = true;
            } else {
                this.showCaptchaAlert = false;
            }
            return ((this.inputsValidNum === this.inputsRequiredNum) && this.hasValidCaptcha);
        },
      
    },
    destroyed(){
        this.removePhoneEvents();
    },

    methods: {
        /**
         * @description watch number of inputs which must be validated
         */
        initNumberOfRequiredInputs() {
           // console.log('init required inputs', this.form.inputs.length)

            for (let input of this.form.inputs) {
                //console.log(input.name, input.required, input.visible)
                if (input.required) {
                    
                        //console.log('champ ', input.name, 'ajouté aux champs requis')
                        this.inputsRequiredNum++;
                    
                }
                
            }

            //console.log('V -- FORM Component : '+ this.inputsRequiredNum + ' champs requis')
        },

        /**
         * init datas to send on submit
         */
        initDatasToSend: function () {
           // console.log('init data to send', this.form.inputs)
            for (var prop of this.form.inputs) {

                if (prop === 'name') {
                    this.datasToSend[prop] = undefined;
                }
            }
           // console.log(this.datasToSend)
        },


        reset() {
            this.inputsValidNum = 0;
            this.showCaptchaAlert = false;
            this.datasToSend = {
                loginApi: 'form'
            }
        },


        validCaptcha() {
            //console.log('valid captcha in form')
            this.hasValidCaptcha = true;
        },

        onValidatePhone(obj){
            const phoneInput = this.form.inputs.find(input => input.name == 'phone')
            this.phoneObject = obj;
            this.datasToSend.phone = obj.e164;

            if(obj.isValid && !phoneInput.isValid){

                if(phoneInput.required){
                    phoneInput.isValid = true;
                    this.inputsValidNum++;
                }
               
               
            } else if (!obj.isValid && phoneInput.isValid){
                this.datasToSend.phone = undefined;

                if(phoneInput.required){
                    phoneInput.isValid = false;
                    this.inputsValidNum--;
                }
            }

            if (this.mustEmitChange) {
                this.$emit('changeValue', 'phone', obj.e164);
            }
        
            //console.log('on validate phone ', obj, obj.e164)
           
        },


        isInputValid(bool, name, value, index = null, clearPwd = null) {
          // console.log('V - FORM COMPONENT valid input in login form with name',  name, value, bool, clearPwd)
           // console.log(this.mustEmitChange)
           
            for (let input of this.form.inputs) {


                if (input.name === name) {
                  // console.log('affect value ', value, 'to data ', name)
                    this.datasToSend[name] = value;
                    if(name == 'password') this.datasToSend.unencrypted = clearPwd
                    if (bool && input.isValid !== true) {

                        if (input.required) {
                            //console.log('inputs valid +1')
                            input.isValid = true;
                            this.inputsValidNum++;
                        }

                    } else if (!bool && input.isValid) {

                        this.datasToSend[name] = undefined;
                        if (input.required) {
                            //console.log('inputs valid -1');
                            input.isValid = false;
                            this.inputsValidNum--;
                        }
                    }

                }
            }
            if (this.mustEmitChange) {
                this.$emit('changeValue', name, value);
            }
            //console.log(this.datasToSend)
        },

    

        /**
         * @description when click on button, action in function of button id
         * @param {string} buttonId 
         */
        onButtonClick(buttonId) {
            //console.log('FORM ON BUTTON CLICK',buttonId)
            this.isSubmitted = true;

            switch (buttonId) {
                case 'form-submit':
                    console.log('submit FORM with datas ', this.datasToSend)
                    this.$emit('submitForm', this.datasToSend);
                    this.$root.tempConfirmDatas.length = 0;
                    break;

                case 'form-forgot-password':
                    this.$emit('submitForm', this.datasToSend);
                    break;
            }
        },
        initPhone(){
            const phoneInput = this.form.inputs.find(input => input.name == 'phone')
            if(phoneInput){
                const select = document.querySelector('.m-select-input input')
         console.log('init phone ', select)
               
                 select.addEventListener('focus', ()=>this.onPhoneFocus(true))
                 select.addEventListener('blur', ()=>this.onPhoneFocus(false))
            }
            
        },
        removePhoneEvents(){
            const phoneInput = this.form.inputs.find(input => input.name == 'phone')
            if(phoneInput){
                const select = document.querySelector('.m-select-input input')
               
                 select.addEventListener('focus', ()=>this.onPhoneFocus(true))
                 select.addEventListener('blur', ()=>this.onPhoneFocus(false))
            }
        },

        onPhoneFocus(bool){
            this.focusPhone = bool
        }

    }

}