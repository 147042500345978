<template lang="html">
	<div id="register-page" class="dynamic-page">
		<h3 class="welcome-title" v-html="title"></h3>

		<PracticalSession v-if="fromPractical && !isConfirmation && practicalTraining" :session="practicalTraining"  :discount="practicalTrainingInfos.discount" :priceToPay="practicalTrainingInfos.price" :organization="organization" />
		<div v-if="step == 1">
			<div class="welcome-message" v-if="step == 1 && !isPaying" v-html="welcomeMessage"></div>
			<form-component v-if="step == 1 && !isPaying" :mustEmitChange="true" v-on:changeValue="keepTempDatas" :form="form" v-on:submitForm="register"></form-component>
			<payment-component v-if="!isConfirmation && practicalTrainingInfos" v-show="step == 1 && isPaying && practicalTrainingInfos && practicalTrainingInfos.price > 0"  ref="payment-comp"  :user="registeredUser" :product="practicalTraining" :amount="practicalTrainingInfos.price*100" @succeed="onPaid" @error="onPaidError"></payment-component>
			<div :class="'cgvu ' + ($root.cgvu ? '' : 'hidden')">
				{{ fromPractical ? $t("678") : $t("372") }}
				<button type="button" class="btn btn-cgu" @click="$root.openCGVU($event)">{{ $t("600") }}</button>
				{{ $t("790") }}
				<button type="button" class="btn btn-cgu" @click="$root.openCGVU($event, 2)">{{ $t("791") }}</button>.
			</div>
		</div>

		<div v-if="step == 2" class="step-2">
			<p>{{ $t("543") }}&nbsp;<strong>{{ registeredUser.email }}</strong>.</p>
			<br/>
			<p>{{ $t("544") }}</p>

			<section id="alert">
				<inline-svg :src="infoIcon"></inline-svg>
				<p v-html="$t('545')"></p>
			</section>
		</div>
	</div>
</template>

<script src="./registerPage-component.js"></script>
