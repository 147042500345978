/**
 * INPUT TEXT COMPONENT
 * check its content, and emit
 * --> 'isValid', true if is valid -->  this.$emit('isValid', true, this.name, this.inputValue)
 * --> 'isValid', false if is valid -->  this.$emit('isValid', false, this.name, this.inputValue)
 * @example <input-text-component v-if="input.type==='text'" :input="input" :index="index" v-on:isValid="isInputValid"></input-text-component>
 */

import {FormService} from 'salvum-utilities';

export default {
    name: 'inputTextComponent',
    components: {
    },
    props: [
        'input', 'index', 
    ],
    data () {
        return {
            focus : false,
            inputValue: this.input.value,
            placeholder: this.input.placeholder,
            alertDisplayed: false,
            className: this.input.name,
            hasIcon: this.input.icon !== undefined ? '' : 'no-icon',
            isValid : false,
            validClass : '',
        }
    },
    emits: ['isValid'],
    mounted: function () {
        //console.log('mounted input text with ',this.input.value)
        //if input isnot empty, valid it
        if (this.input.value !== undefined || this.input.value !== '') {
            let isValid = FormService.textIsValid(this.inputValue);
            this.isValid = isValid;
            this.$emit('isValid', isValid, this.input.name, this.inputValue, this.index);
            this.setPlaceholder();
        }
    },

    methods: {
        deletePlaceholder() {
            this.placeholder = '';
            if (this.$root.alertMessage !== undefined) {
                this.$store.commit('hideAlert');
            }
        },
        setPlaceholder() {
            if (this.inputValue === '') {
                this.placeholder = this.input.placeholder;
            }
        },
        validInput() {
           //console.log('V - INPUT TEXT valid input ', this.inputValue, this.inputValue.trim(), this.input.value);

           let isValid = FormService.textIsValid(this.inputValue.trim());
           
           this.displayAlert(isValid, this.inputValue);
           //this.validClass = isValid ? '' : 'invalid';
          
           this.isValid = isValid;
           this.$emit('isValid', isValid, this.input.name, this.inputValue.trim(), this.index);
           this.setPlaceholder();
           //console.log('is INPUT TEXT valid ? ', this.isValid)
        },


        displayAlert(bool, value) {
           //console.log('dipslay alert ', bool, value)
            this.alertDisplayed = bool ? undefined : this.input.alert;

            if (value.length === 0) {
                if(!this.input.required) this.alertDisplayed = undefined;
                this.validClass = this.input.required ? 'invalid' : '';
            }
            //console.log('display alert champ text ', bool, this.validClass)
        },
        onFocus(bool){
            this.focus = bool;
            this.setPlaceholder();
        }
    }
}