<template lang="html">
    <div id="modal-component"   :class="'modal-component component '" @click="onClick($event)">
        <div id="modal-view">
            <p>{{$t('490')}}</p>
            <div id="modal-buttons">
                <button type="button" class="button  button-style-1 button-round  button-blue" @click="onNo($event)">{{$t('20')}}</button>
                <button type="button" class="button  button-style-1 button-round  button-rose" @click="onYes($event)">{{$t('19')}}</button>
            </div>
        </div>
    </div> 
</template>

<script src="./modal-component.js"></script>

<style scoped lang="scss" src="./modal-component.scss">

</style>

