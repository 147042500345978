/**
 * TOOLS
 * @description useful tools which can be used everywhere
 * need axios https://github.com/axios/axios for http requests
 */

import axios from 'axios';

let Tools = {

    /**
     * @access public
     * @return true | false if navigator is mobile or not
     */
    isMobile (){
        
        //console.log(navigator.userAgent)
        let isIpad = navigator.userAgent.match(/iPad/i) != null || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1),
            isIphone = !isIpad && ((navigator.userAgent.match(/iPhone/i) != null) || (navigator.userAgent.match(/iPod/i) != null)),
            isIos = isIpad || isIphone,
            isAndroid = !isIos && navigator.userAgent.match(/android/i) != null;
            console.log('** TOOLS : isIpad, isIos isIphone isAndroid ', isIpad, isIos, isIphone, isAndroid)
            console.log('** TOOLS : is Mobile', (isIos || isAndroid || (/Android|webOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))));
		return (isIos || isAndroid || (/Android|webOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)));
    },

    
    /**
     * @access public
     * @description to get an external json and use its datas
     * @param {string} url url of the json
     */
    getJson: function (url) {
        //console.log('** TOOLS : get json at url ', url);
        return new Promise((resolve, reject) =>{
             axios.get(url)
            .then(response=>{
                //console.log('** TOOLS : datas from json getted ', response.data);
                resolve(response.data)
            })
            .catch(error=>{
                //console.log('** TOOLS : get json error ', error);
                reject(error)
            })
        })
    },

    /**
     * @access public
     * @description to get an external json and use its datas
     * @param {string} url url of the json
     */
     getJsonAjax: function (url) {
        console.log('`` TOOLS : get json AJAX at url ', url);
        return new Promise((resolve, reject) => {
            let req = new XMLHttpRequest();
            req.timeout = 60000;
            req.addEventListener('load', (res) => {
                let datas = JSON.parse(req.response)
                //console.log(datas)
                resolve(datas);
                req = null;
            })
            req.addEventListener("error", (err)=>{
                console.warn('`` TOOLS get JSON error ', err, req)
                req = null;
                reject(err)
            })
            req.addEventListener("timeout", (err)=>{
                console.warn('`` TOOLS get JSON error timeout ', err, req)
                req = null;
                reject(err)
            })
            req.open('GET', url);
            req.send();
        })
    },

    request : function(url, datas={}){
        return new Promise((resolve, reject) => {
            //console.log('** TOOLS : request to ', url, datas);
            axios.post(url, datas)
                .then(response => {
                    console.log('** TOOLS request reponse:', response)
                    //console.log('** TOOLS request response error:', response.data.error)
                    if (response.data.error !== undefined) {
                        console.warn(response.data.error)
                        reject(userDatas);
                    } else {
                        //console.log('** TOOLS request received datas', response.data)
                        resolve(response.data);
                    }

                })
                .catch(error => {
                    console.warn('** TOOLS request not possible ', error)
                    reject(error)
                })
        })
    },

    /**
     * @access public
     * @param {string} url distant url
     * @param {object} datasToSend 
     */
    postDatas : function(url, datasToSend){
        return new Promise((resolve, reject) =>{
            axios.post(url, datasToSend)
            .then(response=>resolve(response))
            .catch(error=>reject(error))
        })
    },

    /**
     * 
     */
    getURLParameter(url, name) {
       console.log('** TOOLS : get parameter '+name+' from url '+url)
        name = name.replace(/[\[\]]/g, "\\$&");

        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
            
        //console.log('results, ', results)
        if (!results) return null;
        if (!results[2]) return '';

        
        return decodeURIComponent(results[2].replace(/\+/g, " "));
      },

      deleteUrlParams(){
        window.history.replaceState({}, document.title, "/"); 
      },

      redirectIfBrowserIs(browser, url){
       // console.log('must redirect beacause of browser ?', navigator.userAgent.match(browser), url)
                
        if(navigator.userAgent.match(browser)){
            location.href = url;
        }
    },



      /******** IMAGES *********** */
      /* convert image to URI to keep it on storage */
        getImageConvertedToURI(imageUrl, onConverted){
            //console.log('getImageConverted ', imageUrl)
            function toDataURL(src, callback, outputFormat) {
                var img = new Image;

                img.crossOrigin = "Anonymous";
                img.onload = function() {
                  var canvas = document.createElement('CANVAS');
                  var ctx = canvas.getContext('2d');
                  var dataURL;
                  canvas.height = this.naturalHeight;
                  canvas.width = this.naturalWidth;
                  ctx.drawImage(this, 0, 0);
                  dataURL = canvas.toDataURL(outputFormat);
                  callback(dataURL);
                };
                
                img.src = src;
               
              }

              
            toDataURL(imageUrl, onConverted);
                 

            
        }

}


export default Tools;