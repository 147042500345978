/**
 * @description Captcha component, use reCaptcha2 from google
 * emit event 'captchaValid' which could be listened from others components
 * v-on:captcha-valid="callback"
 */


export default {
    name: 'captchaComponent',
    props: [
        'displayAlert'
    ],
    data () {
        return {
            captcha: undefined,
            token: undefined,
            isValid: false,
            id: 'captcha-container',
            config: {
                'size': 'normal',
                'sitekey': '6LfF80AUAAAAAL5Vp7jXwE3Q5pGATY5AUdk4tL5n',
                'callback': this.onValid,
                'error': this.onInvalid
            }
        }
    },
    mounted: function () {
        //console.log('V -- CAPTCHA component mounted with displayalert ', this.displayAlert)
        this.captcha = grecaptcha.render(this.id, this.config);
        this.reset();
    },
    emits: ['captchaValid'],
    methods: {
        /**
         * @description reset captcha
         */
        reset: function () {
            this.isValid = false;
            this.token = undefined;
            grecaptcha.reset();
        },

        /**
         * @description when captcha is valid
         * @param {object} response response sended by captcha api
         */
        onValid: function (response) {
            console.log('V -- captcha valid callback', response)
            this.isValid = true;
            this.token = response;
            this.$emit('captchaValid', true, this.token);
        },

        /**
         * @description when captcha is invalid
         * @param {object} error error sended by captcha api
         */
        onInvalid: function (error) {
            console.log('V -- captcha invalid callback', error);
            this.isValid = false;
            this.token = undefined;
            this.$emit('captchaValid', false, this.token);
        }
    }
}