<template lang="html">
    <div id="modal-component"   :class="'modal-component component '" @click="onClick($event)" >
        <div id="modal-view">
            <button type="button" class="button button-close" @click="$store.commit('showCgu', false)"><font-awesome-icon icon="times-circle" /></button>
            <div class="modal-content">
                <h1 class="h3">{{type === 1 ? $t('92') : $t('792')}}</h1>
                <div class="distant-content" v-html="content"></div>
            </div>
            
        </div>
    </div> 
</template>

<script src="./cgu-modal-component.js"></script>

<style scoped lang="scss" src="./cgu-modal-component.scss">

</style>

