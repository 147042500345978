import { loadStripe } from "@stripe/stripe-js";
/* eslint-disable no-unused-vars */
const PaymentService = {
	stripe: null,
	urls: null,
	async init(urls) {
		this.urls = urls;
		this.stripe = await loadStripe(STRIPE_KEY);
		console.log('/-- PAYMENT SERVICE --/ init with key', STRIPE_KEY, this.stripe, this.urls)
	},

	/**
	 * crée l'intention de paiement stripe
	 * @param {object} product
	 * @param {number} amount
	 * @param {object} user
	 * @returns
	 */
	async createPaymentIntent(product, amount, user) {
		 console.log('/-- PAYMENT SERVICE --/ create payment intent with product  ', product, ' / for user ', user)

		return await fetch(this.urls.createPayment, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-access-token": user.token,
			},
			body: JSON.stringify({
				userid: user.id,
				name: user.name,
				lastName: user.lastName,
				birthDate: user.birthDate,
				phone: user.phone,
				amount: amount ? amount : 0,
				productid: product.id,
				producttype: 1,
			}),
		});
	},

	/**
	 * finalise le paiement
	 * @param {object} product
	 * @param {object} stripeObject
	 * @returns
	 */
	async endPayment(product, amount, user, stripeObject) {
		return await fetch(this.urls.endPayment, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-access-token": user.token,
			},
			body: JSON.stringify({
				userid: user.id,
				amount: amount ? amount : 0,
				productid: product.id,
				stripeobject: stripeObject,
				producttype: 1
			}),
		});
	},

	/**
	 * confirme le paiment carte avec stripe
	 * @param {*} card
	 * @param {*} clientSecret
	 * @returns
	 */
	async payWithCard(card, clientSecret) {
		// console.log('/-- PAYMENT SERVICE --/ pay with card   ', card, clientSecret)
		return await this.stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: card,
			},
		});
	},
};

export default PaymentService;
