
import router from "./router/router";
import { createApp } from 'vue'
//plugins
import VueSocialSharing from 'vue-social-sharing'



//icones
//import { MdIcon } from "vue3-material/src/components/MdIcon/index.js";
import InlineSvg from 'vue-inline-svg';

//pages
import buttonComponent from "./components/others/button/button-component.vue";
import messageViewerComponent from "./components/others/messageViewer/messageViewer-component.vue";
import buttonBackComponent from "./components/others/buttonBack/buttonBack-component.vue";
import modalComponent from "./components/others/modal/modal-component.vue";
import CguModalComponent from "./components/others/cguModal/cgu-modal-component.vue";
//Store
import store from "./store/store";

//Services
import Tools from "@/js/utils/tools";
import UserService from "./services/UserService";


import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
library.add(faTimesCircle);

/**
 * @description Main Vue for admin interface
 */
export class MainVue {
	/**
	 * @param {object} preloader preloader used before main vue is showed
	 * @param {object} appConfig base config for app (config.json)
	 * @param {object} appDatas distant datas from external json
	 * @param {object} services services to use in Vue Application. Object has form {serviceName : serviceClass, serviceName : serviceClass...}. Service can be accessed in all componentns by this.$root.services.serviceName
	 *
	 */
	constructor(preloader, appConfig, appDatas, i18n) {
		const app = createApp({
			components: {
				buttonComponent,
				messageViewerComponent,
				buttonBackComponent,
				modalComponent,
				CguModalComponent,
			},
			data(){
				return {
					imgUrl: 'assets/img/',
					pageToDisplay: undefined,
					config: appConfig,
					store: store,
					tools: Tools,
					isMobile: Tools.isMobile(),
					urlParams: {},
					pageParameters: undefined,
					tempConfirmDatas: {
						name : '',
						lastName : '',
						birthDate : '',
						phone : ''
					},
					isLoading: true,
					isReady: false,
					cgvu: null,
					dpo: null,
					cguType : 1
				}
			} ,
			beforeCreate: function() {
				//console.log('V - main vue before create ')
				//redirection for IE11

				store.commit("setDatas", appDatas);
				store.commit("setAvatars", appDatas.avatars);
			},
			created: function() {
				//this.tools.redirectIfBrowserIs(/(MSIE |Trident.*rv[ :])([0-9]+)/, this.config.serverConfig.redirectionUrl);
				console.log("V - main vue created with config", this.config);
			
				this.initPageToDisplay();
				this.getCgvuContent();
				this.getDpoContent();
			},
			mounted: function() {},
			updated: function() {},
			//dynamic properties to watch
			computed: {
				isAuth: function() {
					//console.log('V -- is auth in main vue ',store.state.user.isAuth)
					return store.state.user.isAuth;
				},
				actualUser: function() {
					//console.log('in main vue, actual user updated ', store.state.user.actualUser)
					return store.state.user.actualUser;
				},
				datas: function() {
					return store.getters.getDatas;
				},
				alertMessage: function() {
					return store.getters.getAlertMessage;
				},
				showModal: function() {
					return store.getters.getShowModal;
				},
				showCgu: function() {
					return store.getters.getShowCgu;
				},
				screenWidth: function() {
					return window.innerWidth;
				},
			},
			methods: {
				/**
				 * show Main Vue
				 */
				show: function() {
					// console.log('show')
					this.isReady = true;
					this.isLoading = false;
				},

				start(whenShowed) {
					this.show();
					this.isStarted = true;
				},

				/****   NAVIGATION ***************************************/

				initPageToDisplay() {
					console.log("V -- MAIN VUE : init page to display ", this.$route.name, "with auth ", this.isAuth);
					let query = this.$route.query;

					let userId = query.user,
						account = query.account,
						origin = query.origin,
						returnsTo = query.returnsTo,
						from = query.from,
						browser = query.browser;

					if (account === null || account === undefined || account === "undefined" || account == "null") {
						account = this.detectAccount();
						console.log("account detected = ", account);
					}
					console.log(UserService.getAccount(account), UserService.getAccount(account) !== undefined);
					this.urlParams = {
						userId: userId !== null && userId !== undefined && userId !== "undefined" ? userId : undefined,
						account: UserService.getAccount(account) !== undefined ? UserService.getAccount(account).id : undefined,
						origin: origin !== undefined ? origin : this.isMobile ? "app" : "webApp",
						from: from,
						returnsTo: returnsTo,
						browser: browser,
					};
					console.log("url Params", this.urlParams);
					console.log("page to display : " + this.$route.name + " with params ", this.urlParams, "and auth ", this.isAuth);

					this.start();
				},

				onAppModal(params) {
					console.log("close app modal with has App", params.hasApp);
					this.$store.commit("hideModal");

					if (params.hasApp !== undefined) {
						//console.log('must redirect', params.cb)
						params.cb();
					}
				},

				goToApp(page, options = {}) {
					console.log("go to app with page ", page, "and options ", options);

					const user = options.user || undefined;
					const promocode = this.$router.currentRoute.value.query.promocode || null;

					let isIpad = navigator.userAgent.match(/iPad/i) != null || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1),
						isIphone = !isIpad && (navigator.userAgent.match(/iPhone/i) != null || navigator.userAgent.match(/iPod/i) != null),
						isIos = isIpad || isIphone,
						isAndroid = !isIos && navigator.userAgent.match(/android/i) != null,
						browser = this.$router.currentRoute.value.query.browser ? "&browser=1" : "",
						prefix = (isIos || isAndroid) && !browser ? "" : "/#/",
						origin = this.$router.currentRoute.value.query.origin,
						scheme = user && user.main_provider.scheme ? user.main_provider.scheme : this.config.defaultScheme,
						returnsTo = this.$router.currentRoute.value.query.returnsTo,
						bundleId = user && user.main_provider.bundleid ? user.main_provider.bundleid : this.config.defaultBundleId,
						urlString =
							prefix +
							(returnsTo !== undefined ? returnsTo : page) + '?' + 
							(user ? "user=" + user.id + "&token=" + user.token : "") +
							(options.userStatus ? "&userStatus=" + options.userStatus : "") +
							"&from=" +
							this.$router.currentRoute.value.query.from +
							(returnsTo !== undefined ? "&returnsTo=" + returnsTo : "") +
							(user && user.organizationurl !== undefined ? "&organization=" + user.organizationurl : "") +
							(scheme !== undefined ? "&scheme=" + scheme : "") +
							(bundleId !== undefined ? "&bundleid=" + bundleId : "") +
							browser +
							(promocode ? "&promocode=" + promocode : "");

					//console.log('SCHEME is ', scheme)
					//console.log('BUDNLEID is ', bundleId)
					//console.log('isIos, isIpad, isIphone', isIos, isIpad, isIphone)
					console.log("returnsTo : ", returnsTo);
					console.log("go to page : ", urlString);

					let toApp = () => {
						console.log("to App with isIos, isAndroid ", isIos, isAndroid);
						if (isIos) {
							setTimeout(() => {
								if (!document.webkitHidden) {
									console.log("is Ios go to window location ", scheme + "://" + urlString);
									window.location = scheme + "://" + urlString;
									return false;
								}
							}, 5);
						} else if (isAndroid) {
							//console.log('is android go to', scheme + urlString)

							console.log("is android go to window location ", scheme + "://" + urlString);
							window.location = scheme + "://" + urlString;

							return false;
						}
					};

					let toStore = () => {
						let url = isIos ? user.main_provider.iosLink : user.main_provider.androidLink;

						console.log("to store go to new", url, this.config);
						if (isIos) {
							setTimeout(() => {
								console.log("dans le settimeout ", !document.webkitHidden);
								if (!document.webkitHidden) {
									console.log("dans le settimeout window open ", url);
									window.open(url.toString());

									return false;
								}
							}, 5);
						} else if (isAndroid) {
							console.log("is android go to", url);
							window.location = url;

							return false;
						}
					};

					if (this.$root.isMobile && !browser) {
						if (origin === "mobileApp") {
							console.log("from mobile go to app");
							toApp();
						} else {
							console.log("not mobile display showmodal");
							this.$store.commit("showModal", { onYes: toApp, onNo: toStore });
						}
					} else {
						console.log("is web or stay browser , go to window location ", this.config.serverConfig.webAppUrl + urlString);
						window.location.href = this.config.serverConfig.webAppUrl + urlString;

						return false;
					}
				},

				/****   UTILS ***************************************/
				detectAccount() {
					//console.log('detect account')
					let defaultAccount = 1;
					return defaultAccount;
				},

				getCgvuContent() {
					console.log("get gvu content", this.$root.config.serverConfig.cgvuContent);
					return new Promise((res, rej) => {
						Tools.getJsonAjax(this.$root.config.serverConfig.cgvuContent)
							.then((response) => {
								//console.log('cgvu response', response)
								this.cgvu = response;
								
								res(response);
							})
							.catch((err) => {
								console.warn(err);
								rej(err);
							});
					});
				},
				getDpoContent() {
					console.log("get gvu content", this.$root.config.serverConfig.dpoContent);
					return new Promise((res, rej) => {
						Tools.getJsonAjax(this.$root.config.serverConfig.dpoContent)
							.then((response) => {
								//console.log('cgvu response', response)
								this.dpo = response;
								
								res(response);
							})
							.catch((err) => {
								console.warn(err);
								rej(err);
							});
					});
				},

				scrollToTop(){
					document.getElementById('salvum').scrollTo(0,0)
				},
				openCGVU(evt, type = 1) {
					//document.addEventListener('click',onClick)
					console.log('open gcvu with type ', type)
					evt.preventDefault();
					this.cguType = type
					this.$store.commit("showCgu", true);
					
				},
			},
		});
		app
		.use(VueSocialSharing)
		.use(store)
		.use(router)
		.use(i18n)
		//.use(i18n)
		.component('font-awesome-icon', FontAwesomeIcon)
		.component('inline-svg', InlineSvg);
		//.use(MdIcon)
		app.mount('#salvum')
		return app;
	}
}
