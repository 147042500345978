<template lang="html">
  <div :class="'form-input form-input-password ' + className + ' '+hasIcon">
    
    <div :class="'form-input-content '+(focus ? 'focus' : 'no-focus')">
      <label :for="index !==undefined ? 'form-'+index : undefined" v-if="input.label !== undefined && input.label !== ''">{{input.label}}<!--span class="input-required" v-if="input.required">*</span--></label>
      <p v-if="alertDisplayed" class="input-alert">{{input.alert}}</p>
      <input  @click="deletePlaceholder" autocapitalize="off" :autocomplete="input.autocomplete"  @focusin="onFocus(true)" @focusout="onFocus(false)" :disabled="input.disabled"  :id="index !==undefined ? 'form-'+index : undefined" :required="input.required"  :name="input.name" :placeholder="placeholder" :type="input.type"  v-model="inputValue" v-on:input="validInput" >
    </div>
    <p class="form-input-info" v-if="input.info !== undefined">{{input.info}}</p>
    <router-link class="password-link" v-if='input.forgottenPassword !== undefined' :to="{path : 'password', query:  $router.currentRoute.value.query}">{{input.forgottenPassword.text}}</router-link>
  </div>
  
</template>

<script src="./input-password-component.js"></script>

<style scoped src="./input-password-component.css"></style>

