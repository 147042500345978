/**
 * @example <button-component id="id to give to button" bt-class="class to add to button" v-on:buttonClick="onClickCallback" text="The text to display"></button-component>
 * on click, emit a 'buttonClick' event to parent
 */
import ButtonComponent from '../button/button-component.vue';
 
export default {
    name: 'buttonbackComponent',
    components: {
        ButtonComponent,
    },
    props: [
        'mobileBack',  'onBack'
    ],
    data () {
        return {
            backIcon: "assets/img/icon-back.svg",
            text: ''
        }
    },
    mounted: function () {
        console.log('BACK BUTTON mounted with icon ',  this.backIcon, this.onBack)
        //console.log(this.$store)
    },
    methods: {
        goBack() {
           
                console.log('back button on web', window.history, window.history.length, this.$route.query.origin);
                this.$router.go(-1);
              


        }
    }
}