<template lang="html">
  <div id="captcha-component">
    <div  id="captcha-container" class="g-recaptcha" ></div>
    <p v-if="displayAlert" class="captcha-alert" v-html="$t('246')"></p>
  </div>
</template>

<script src="./captcha-component.js"></script>

<style scoped src="./captcha-component.css">

</style>

