/**
 * INPUT PASSWORD COMPONENT
 * check its content, and emit
 * --> 'isValid', true if is valid -->  this.$emit('isValid', true, this.name, this.inputValue)
 * --> 'isValid', false if is valid -->  this.$emit('isValid', false, this.name, this.inputValue)
 * @example <input-password-component  :input="input" :index="index" v-on:isValid="isInputValid"></input-password-component>
*/

import {FormService} from 'salvum-utilities';

export default {
    name: 'inputPasswordComponent',
    components : {
    },
    props: [
        'input', 'index'
    ],
    data () {
        return {
            focus : false,
            inputValue: this.input.value !== undefined ? this.input.value : '',
            placeholder : this.input.placeholder,
            alertDisplayed: false,
            className: '',
            hasIcon : this.input.icon !== undefined ? '' : 'no-icon',
            isValid : false
        }
    },
    mounted : function(){
        //console.log('PASSWORD COMPONENT ', this.input)
    },
    emits: ['isValid'],
    methods: {
        deletePlaceholder(){
            this.placeholder  = '';
            if(this.$root.alertMessage !== undefined){
                this.$store.commit('hideAlert')
            }
        },
        setPlaceholder() {
            if(this.inputValue === ''){
                this.placeholder = this.input.placeholder;
            }
        },
        validInput() {
           // console.log('V - INPUT PASSWORD valid input ', this.inputValue, this.input);
            let isValid = this.input.noValidation === true ? true : FormService.passwordIsValid(this.inputValue, this.input.regex),
                cryptedPwd = FormService.encryptPassword(this.inputValue);
            if (this.input.noValidation !== true) this.displayAlert(isValid, cryptedPwd);
            this.isValid = isValid;
            this.$emit('isValid', isValid, this.input.name, cryptedPwd, this.index, this.inputValue);
            this.setPlaceholder();
        },


        displayAlert(bool, value) {
            this.alertDisplayed = bool ? undefined : this.input.alert;
            this.className = bool ? 'valid' : 'invalid';
            if (value.length === 0) {
                this.alertDisplayed = undefined;
                this.className = this.input.required ? 'invalid' : undefined;
            }
        },
        onFocus(bool){
            this.focus = bool;
            this.setPlaceholder();
        }
    }
}