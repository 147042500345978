<template lang="html">
  <div id="main-page" :class="'page '+ $route.name + ' ' +(organization !== undefined ? 'organization' : '')" >

        <div class="page-left">
            <div class="page-content">
               
                <button-back-component v-if="$router.currentRoute.value.query.origin == 'webApp' || $router.currentRoute.value.name == 'password'"></button-back-component>
        

                <div id="header">
                    <img id="logo" :src="logo" />
                    <img v-if="organization !== undefined" id="orga-logo" :src="organization.image" />
                </div>

                <router-view :organization="organization"></router-view>  
                 
           	</div>   

            <div class="page-footer">

                <div class="page-content"  v-if="$router.currentRoute.value.query.origin != 'mobileApp'">
                    <button key="help" class="button button-help button-style-1 button-round  button-white"  type="button" v-on:click="goToFaq">
                    <inline-svg :src="helpIcon"></inline-svg>
                    <span v-html="$t('373')"></span>
                    </button>
                </div>

                <div class="line">
                </div>
			<div class="page-content" v-if="footer">
				<div v-html="footer.text"></div>
				<router-link  class="footer-link" :to="{name : footer.url, query:  $router.currentRoute.value.query}"  v-html="footer.link"></router-link>
			</div>

            </div>

        </div>
       
        <div class="page-right" v-if="$root.screenWidth >= 992">
            <img :src="background" :srcset="background1x + ' 1x, ' + background2x + ', 2x'" alt="Salvum, l'application des gestes qui sauvent" title="Salvum" />
            <div class="page-content">
                <h1 v-html="$t('375')"></h1>
                <h2 v-html="$t('71')"></h2>
                    <!--button key="discover"  v-if="$router.currentRoute.value.query.origin != 'mobileApp'" class="button button-discover button-style-1 button-round  button-transparent"  type="button" v-on:click="goToSalvum">
                        <span v-html="$t('376')"></span>
                    </button-->
                    <button key="help" v-if="$router.currentRoute.value.query.origin != 'mobileApp'"  class="button button-help button-style-1 button-round  button-transparent"  type="button" v-on:click="goToFaq">
                        <inline-svg :src="helpIcon"></inline-svg>
                        <span v-html="$t('373')"></span>
                    </button>
            </div>
        </div>
	</div>

</template>

<script src="./mainPage-component.js"></script>


