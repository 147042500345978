

import Tools from '@/js/utils/tools';
import PracticalSession from '@components/practical-session/practical-session.vue';

export default {
    name: 'confirmationPage',
    components: {
        PracticalSession
    },
    props: [
     'organization'
    ],
    data() {
        return {
          
            practicalTrainingInfos : null,
            practicalTraining: null,
        }
    },
    destroyed: function () {
        this.$store.commit('hideAlert');
    },
    beforeMount(){
        //on récupère les infos sur la session de formatin pratique
			this.practicalTrainingInfos = {
				id: Tools.getURLParameter(window.location, "ptId"),
				discount: Tools.getURLParameter(window.location, "ptDiscount"),
			};

			this.practicalTraining = this.$store.getters.getDatas.practicalTrainings.find((session) => session.id == this.practicalTrainingInfos.id);
            console.log('confirmation page with training', this.practicalTraining)

           
    },

    mounted(){
        
       
        this.$root.scrollToTop();
     
    },
    
    methods: {
       goToRegister(){
        this.$router.push( {name : 'register', query : {...this.$route.query}})
       },
       goToPractical(){
         this.$root.goToApp('practical' , {
             promocode : this.$route.query.promocode
         }
         );
       }
    }
}