/**
 * Manage shopping, buying, promocode etc
 */

import store from '../store/store';
import _remove from 'lodash/remove';
import _find from 'lodash/find';
import UserService from './UserService';
import axios from 'axios';
import Storage from '../classes/storage.class';

let ShopService = {

    /**
     * initialization with config
     * @param {object} config 
     */
    init(config) {
        this.urls = config.urls;
        /*this.storageName = "Salvum:basket";
        this.shopStorage = undefined;

        //init storage
        this.shopStorage = new Storage(
            this.storageName,
            (storageBasket) => {
                this.updateBasketFromUser(storageBasket);
            },
            (storageBasket) => {
                //create empty basket
                storageBasket.updateStorage(new Array());
                this.updateBasketFromUser(storageBasket.getStorage());
            }
        );*/
    },

    /**
     * add product in storage
     * @param {object} product 
     */
    addProduct(product) {
        return new Promise((resolve, reject) => {
            if (this._isProductAlreadyInBasket(product)) {
                reject();
            } else {
                store.commit('addProduct', product);
                this.shopStorage.updateStorage(store.getters.getBasket);
                resolve(store.getters.getBasket);
            }
        })
    },

    /**
     * remove product from stroage and app store
     * @param {object} product 
     * @param {function} onRemoved 
     */
    removeProduct(product, onRemoved) {
        store.commit('removeProduct', product)
        this.shopStorage.updateStorage(store.getters.getBasket);
        onRemoved(store.getters.getBasket);
    },

    /**
     * update basket from user state
     * @param {array} storageBasket 
     */
    updateBasketFromUser(storageBasket) {

        if (UserService.isLogged) {
            let userBasket = JSON.parse(store.getters.getActualUser.basket),
                newBasket;


            //detect if product in storage has already been buyed by user logged or exist in user basket
            if (storageBasket.length > 0) {
                storageBasket = this._cleanAlreadyBuyed();
                storageBasket = this._cleanAlreadyInUserBasket();
            }

            //if user has basket, merge it with cleaned storage basket
            if (userBasket !== null && userBasket.length > 0) {
                newBasket = (storageBasket.length > 0) ? userBasket.concat(storageBasket) : userBasket;

                //else take storage basket
            } else {
                newBasket = storageBasket;
            }

            store.commit('setBasket', newBasket);
            UserService.updateUserData('basket', JSON.stringify(newBasket))
            this.shopStorage.updateStorage(newBasket);

        } else {
            store.commit('setBasket', storageBasket);
        }
    },

    /**
     * watch if product in basket is already existing in logged user trainings
     */
    _cleanAlreadyBuyed() {
        let storageBasket = this.shopStorage.getStorage(),
            userTrainings = store.getters.getActualUser.trainings;


        //remove product from basket if already buyed
        _remove(storageBasket, function (storeProduct) {

            let mustBeRemoved = false;
            let trainingUser = _find(userTrainings, function (training) {
                return (training.trainingid !== undefined && training.status !== 'available' && training.trainingid === storeProduct.id);
            })

            if (trainingUser !== undefined) {
                mustBeRemoved = true
            }
            return mustBeRemoved;
        })

        this.shopStorage.updateStorage(storageBasket);
        return storageBasket;
    },

    /**
     * if product is already in user basket, clean storage
     */
    _cleanAlreadyInUserBasket() {
        let storageBasket = this.shopStorage.getStorage(),
            userBasket = JSON.parse(store.getters.getActualUser.basket);

        if (userBasket.length > 0) {

            //remove product from basket if already in user basket
            _remove(storageBasket, function (storeProduct) {
                let mustBeRemoved = false;

                let productUser = _find(userBasket, function (product) {
                    return storeProduct.id === product.id
                })

                if (productUser !== undefined) {
                    mustBeRemoved = true
                }

                return mustBeRemoved;
            })
        }

        this.shopStorage.updateStorage(storageBasket);
        return storageBasket;
    },

    /**
     * watch il product exist already in basket
     * @param {object} product 
     */
    _isProductAlreadyInBasket(product) {
        let isAlreadyInBasket = _find(store.getters.getBasket, (existingProduct) => {
            return existingProduct.id === product.id;
        })
        return isAlreadyInBasket;
    },

    /**
     * clean basket
     */
    cleanBasket() {
        store.commit('setBasket', []);
        this.shopStorage.updateStorage(store.getters.getBasket);
        return UserService.updateUserData('basket', JSON.stringify([]))
    },

    /**
     * empty storage basket
     */
    emptyStorage() {
        this.shopStorage.updateStorage([]);
    },

    /**
     * check validity of promocode
     * @param {string} promocode 
     * @param {object} user 
     * @param {array} trainings 
     */
    checkPromocode(promocode) {
        console.log('SHOP check promocode ', promocode)
        return new Promise((resolve, reject) => {
            if(promocode !== undefined && promocode !== '' ){
                //console.log('promocode is defined', promocode)
                axios.post(this.urls.checkPromocode, {
                    promocode: promocode
                })
                .then(response => {
                    if (response.data.status == 0) {
                        reject(response.data);
                    }
                    else if (response.data.status == 1) {
                        resolve(response.data);
                    }
                })
                .catch(error => {
                    console.log('error', error)
                    reject(error)
                })
            } else {
                console.warn('promocode is not defined' , promocode)
                resolve('no promocode');
            }
           
        })
    }
}

export default ShopService;