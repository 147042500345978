

import { createRouter, createWebHistory } from 'vue-router'
import UserService from '../services/UserService';

//external file for routes
import routes from './routes';

console.log('ROUTER WITH BASE URL ', import.meta.env.BASE_URL)
const router = createRouter({
   //mode : "history",
    routes : routes,
    history: createWebHistory(import.meta.env.BASE_URL +'#/'),
});

//Before each route, test is user is Auth, then redirect to login if he's not
router.beforeEach((to, from, next)=>{
    console.log('BEFORE EACH ROUTER', from.path, to.path)
    console.log(to)
    
    if(to.matched.some(record => record.meta.requiresAuth)){
        
        //check if user is authorized (token and id match)
        UserService.checkIfAuthorized(to.query.user, to.query.token)
        .then(()=>{
            console.log('ROUTER user authorized')
            next();
        })
        .catch(()=>{
            console.log('ROUTER user not authorized')
            next({
                replace : false,
                name : 'login',
                query : {
                    origin : to.query.origin,
                    from: 'home'
                }
            })
        })
      
    } else {
        console.log('ROUTER route do not ned to be authorized')
        next();
    }
    
})

export default router;