<template lang="html">
    <div id="password-reset-page" class="dynamic-page" >
        <h3 v-html="$t('388')"></h3>
        <form-component   :form="resetForm" v-on:submitForm="sendPassword"></form-component>
    </div>
</template>

<script src="./passwordResetPage-component.js"></script>


