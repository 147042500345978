//import facebookLoginButtonComponent from '@components/login/facebook-login-button/facebook-login-button-component.vue';
//import googleLoginButtonComponent from '@components/login/google-login-button/google-login-button-component.vue';
import FormComponent from '@components/form/form-component.vue';
import buttonBackComponent from '@components/others/buttonBack/buttonBack-component.vue';
import UserService from '@services/UserService';
//import GoogleLoginService from '@components/../services/GoogleLoginService';
//import FBLoginService from '@components/../services/FBLoginService';



export default {
    name: 'loginPage',
    components: {
        //facebookLoginButtonComponent,
        //googleLoginButtonComponent,
        FormComponent,
        buttonBackComponent
    },
    props: [
        'cordovaIsActive', 'parameters', 'organization'
    ],
    data() {
        return {
            welcomeMessage: null,
            alertMessage: {},
            userToCheck: {
                login: undefined,
                mdp: undefined
            },
            form: {
                hasCaptcha: false,
                isCaptchaVisible: true,
                inputs: [{
                        name: "login",
                        label: this.$t('382'),
                        placeholder: this.$t('181'),
                        autocomplete: 'off',
                        alert: this.$t('79'),
                        type: "text",
                        value: '',
                        required: true,
                        style: 'input',
                        visible: true
                    },
                    {
                        name: "password",
                        label: this.$t('378'),
                        placeholder: this.$t('180'),
                        alert: this.$t('81'),
                        type: "password",
                        autocomplete: 'off',
                        value: '',
                        required: true,
                        style: 'input',
                        visible: true,
                        forgottenPassword: {
                            text: this.$t('73'),
                            class: "forgot-password  button-link",
                            id: "form-forgot-password"
                        }
                    }
                ],
                buttons: [{
                    text: this.$t('72'),
                    id: "form-submit",
                    class: "button-submit button-round button-style-1 button-blue",
                    disabled: true,
                    visible: true
                }]
            },
            isLogging: false
        }
    },
    created: function () {
        //console.log('V -- LOGIN PAGE CREATED get user in storage : ', UserService.getStorageUser())
        if (!this.cordovaIsActive) {
            //check if api are already logged
            // this.checkApiStatus();
        }
    },
    mounted: function () {
        if (this.$route.params.displayAlert !== undefined) {
            this.$store.commit('showAlert', {
                confirm: this.$route.params.displayAlert
            });
        }
        if (this.$route.params.welcomeMessage !== undefined) {
            this.welcomeMessage = this.$route.params.welcomeMessage
        }
        console.log('login page mounted with message ', this.$route, this.$route.params.welcomeMessage)
    },
    methods: {

        /*checkApiStatus() {
            console.log('V -- LOGIN PAGE : check api status')

            GoogleLoginService.checkUserState()
                .then(userDatas => {
                    console.log('google is logged, so check in database')
                    this.checkIfUserExist(userDatas, 'google')
                        .then((user) => {
                            console.log(' google profile exist in database, NEED TO CONNECT IT')
                        })
                        .catch(() => {
                            console.log(' google not existing in database, try with facebook')
                            FBLoginService.checkUserState()
                                .then(userDatas => {
                                    this.checkIfUserExist(userDatas, 'facebook')
                                        .catch(() => {
                                            //console.log(' facebook profile not existing in database')
                                        })
                                })
                        })
                })
                /*.catch(() => {
                    //console.log('not connected with google, try with facebook')
                    FBLoginService.checkUserState()
                        .then(userDatas => {
                            this.checkIfUserExist(userDatas, 'facebook')
                                .then((user) => {
                                    //console.log(' facebook profile exist in database, NEED TO CONNECT IT')
                                })
                                .catch(() => {
                                    //console.log(' facebook profile not existing in database')
                                })

                        }).catch(() => {
                            //console.log(' facebook not connected')
                        })

                })
        },*/

        /**
         * check if user exist in database
         * @param {object} user 
         */
        checkIfUserExist: function (user, loginApi) {

            if (!this.isLogging) { // avoid double click
                this.isLogging = true;

                console.log('V -- LOGIN PAGE check if user exist', user, loginApi)
                console.log('V -- LOGIN PAGE button once', this.form.buttons[0].once)

                this.$store.commit('showAlert', 'wait');

                //transform user in formatted one
                let formattedUser = UserService.getFormattedUser(user)
                if (loginApi) formattedUser.loginApi = loginApi;
                //check user in DB
                UserService.checkIfUserExistInDB(formattedUser)
                    .then(existingUser => {
                        //console.log('existing user is ', existingUser);
                        this.$store.commit('hideAlert');
                        formattedUser = null;
                        this.$root.goToApp('trainings', {
                            user : existingUser ,
                            userStatus : 'logged'
                        });
                        existingUser = null;
                    })
                    .catch((error) => {
                        this.$store.commit('showAlert', {
                            error: error
                        });
                        this.isLogging = false;
                    });
            }

        },

        /** go to reset password page */
        resetPassword: function (formDatas) {
            this.$router.push('password');
        }

    }
}