/**
 * INPUT EMAIL COMPONENT
 * check its content, and emit
 * --> 'isValid', true if is valid -->  this.$emit('isValid', true, this.name, this.inputValue)
 * --> 'isValid', false if is valid -->  this.$emit('isValid', false, this.name, this.inputValue)
 * @example <input-email-component v-if="input.type==='email'" :input="input" :index="index" v-on:isValid="isInputValid"></input-email-component>
*/
import {FormService} from 'salvum-utilities';

export default {
    name: 'inputEmailComponent',
    components : {
    },
    props: [
        'input', 'index'
    ],
    data () {
        return {
            focus : false,
            inputValue: this.input.value,
            placeholder : this.input.placeholder,
            alertDisplayed: false,
            className: '',
            hasIcon : this.input.icon !== undefined ? '' : 'no-icon',
            isValid : false
        }
    },
    mounted(){
        //console.log('V - INPUT EMAIL with input ', this.input);
        if(this.inputValue) this.validInput();
    },
    emits: ['isValid'],
    methods: {
        deletePlaceholder() {
            this.placeholder = '';
            if(this.$root.alertMessage !== undefined){
                this.$store.commit('hideAlert')
            }
        },
        setPlaceholder() {
            if(this.inputValue === ''){
                this.placeholder = this.input.placeholder;
            }
        },
        validInput() {
            //console.log('V - INPUT EMAIL valid input ', this.inputValue);
            let emailValue = this.input.domain ? this.inputValue+'@'+this.input.domain : this.inputValue;
            //console.log(emailValue)
            let isValid =  FormService.emailIsValid(emailValue);
            this.isValid = isValid;
            this.displayAlert(isValid, emailValue);
            this.$emit('isValid', isValid, this.input.name, emailValue, this.index);
            this.setPlaceholder();
           // console.log('EMAIL is valid ', this.isValid)
        },

        displayAlert(bool, value) {
            this.alertDisplayed = bool ? undefined : this.input.alert;
            this.className = bool ? 'valid' : 'invalid';
            if (value.length === 0) {
                this.alertDisplayed = undefined;
                this.className = this.input.required ? 'invalid' : undefined;
            }
        },
        onFocus(bool){
            this.focus = bool;
        }
    }
}