
import { createI18n } from 'vue-i18n'
//import App  from '../app';


/**
 * @external http://kazupon.github.io/vue-i18n
 */
let VueTranslateService = {

    i18n : undefined,
    messages : {}, // array
    defaultLang : 'fr',
    actualLang : undefined, 
    init(langs) {
		console.log('VUE TRANSLATE SERVICE init with langs ', langs)
		

		const localLang = window.navigator.language.split('-')[0]
		console.log('local lang ', window.navigator.language, localLang )
		//specify which lang to use
		const langToUse = langs[localLang] ? localLang : this.defaultLang
		this.actualLang = this.actualLang || langToUse;

		
		for (const lang in langs) {
			this.messages[lang] = langs[lang];
		}
	
		//replace @ by {'@'} to avoid errors
		for(const id in this.messages){
			const lang = this.messages[id]
			for(const itemId in lang){
				let item = lang[itemId]
				if(item.includes('@')){
					lang[itemId] = item.replace('@', '{\'@\'}')
					//console.log(item)
				}
			}
		}
			
		
	
		this.i18n = createI18n({
			locale: this.actualLang,
			fallbackLocale : this.defaultLang,
			messages: this.messages,
		});

		//App.use(this.i18n);
	},

    getTexts(path){
        console.log('get texts ', path)
        let langObject = this.i18n.getLocaleMessage(this.i18n.locale);
        console.log(langObject[''+path+''])
        console.log(path)
        return langObject[path];
    }
    

}

export default VueTranslateService;