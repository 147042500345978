import {Spinner} from 'spin.js';
export class Preloader {

    /**
     * 
     * @param {string} target DOM target in which display the preloader
     */
    constructor(target){
        this.target = document.getElementById(target);
        this.options = {
            lines: 13, // The number of lines to draw
            length: 28, // The length of each line
            width: 3, // The line thickness
            radius: 42, // The radius of the inner circle
            scale: 0.75, // Scales overall size of the spinner
            corners: 0, // Corner roundness (0..1)
            color: '#fff', // #rgb or #rrggbb or array of colors
            opacity: 0.5, // Opacity of the lines
            rotate: 7, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            speed: 1, // Rounds per second
            trail: 47, // Afterglow percentage
            fps: 20, // Frames per second when using setTimeout() as a fallback in IE 9
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            className: 'spinner', // The CSS class to assign to the spinner
            top: '50%', // Top position relative to parent
            left: '50%', // Left position relative to parent
            shadow: false, // Whether to render a shadow
            position: 'absolute' // Element positioning
        }

        this.spinner = new Spinner(this.options).spin(this.target);
        //console.log('preloader setted in ', this.target)
    }

    /**
     * @description hide preloader
     */
    hide() {
        this.target.style.display = 'none';
    }
}