
//css
import 'maz-ui/styles' 
import '@/css/sass/main.scss';
//components
import { Preloader } from '@/js/components/preloader-component.js';
import { MainVue } from '@/js/main_vue.js';

//aply css theme
//import theme from '../css/sass/themes/basic/basic.scss';

//import json
import config from '@/assets/config.json';

import Tools from '@/js/utils/tools.js';
//import FBLoginService from '@services/FBLoginService.js';
//import GoogleLoginService from '@services/GoogleLoginService.js';
import UserService from '@services/UserService.js';
//we can not use salvum-utilities here because of vue3
import VueTranslateService from '@services/VueTranslateService.js';
import ShopService from '@services/ShopService.js';
import PaymentService from "@services/PaymentService.js";

//console.log('config is ', config)

let App = {
    //keep main vue
    vue: undefined,
    //init all services before create Vue
    initServices() {
        console.log('APP INIT SERVICES', config)
        UserService.init(config.serverConfig.user);
        ShopService.init(config.serverConfig.shop);
        PaymentService.init(config.serverConfig.stripe);
        Tools.isMobile();
        this.init('fr');
    },

    /*initSocials(cb){
        
        FBLoginService.initForWeb(
            config.api.facebookAppId,
            () => {
                GoogleLoginService.init(
                    false,
                    config.api.googleClientWebId,
                    cb
                );
            }
        )
    },*/
    //
    init(actualLang) {
       
        //construct Preloader
        let preloader = new Preloader('preloader');

        let orgaParameterName = 'organization';
        let organizationName = Tools.getURLParameter(window.location, orgaParameterName) === null || Tools.getURLParameter(window.location, orgaParameterName).length === 0 ? null : Tools.getURLParameter(window.location, orgaParameterName);
        let fromPracticalPage = Tools.getURLParameter(window.location, 'from') === 'practical'; 
        let bundleId = Tools.getURLParameter(window.location, 'bundleid')
        console.log('APP.JS :::: organisation is ',  organizationName);
        console.log('APP.JS :::: bundleid is ',  bundleId);

        //get datas
        Tools.request(config.serverConfig.datasUrl, { 
            version: Tools.isMobile() ? 'mobile' : 'web',
            organization: organizationName,
            bundleId : bundleId ? bundleId : config.defaultBundleId,
            fromPractical : fromPracticalPage
         })
            .then(datas => {
                console.log('APP.JS :::: datas', datas)
                VueTranslateService.init(datas.txts, actualLang);
                this.vue = new MainVue(preloader, config, datas, VueTranslateService.i18n);
            })
    }
}

/* LAUNCH APP */
Tools.getJson('./urls.json').then(datas=>{
    console.log('serverConfig ', datas.serverConfig)
    config.serverConfig = datas.serverConfig;
    App.initServices();
})



export default App;