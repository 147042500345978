<!--
PAYMENT PAYMENT COMPONENT ONLY CARD ELEMENT 
use https://stripe.com/docs/payments/payment-element
params : 
- product {object} object to pay

events :
- succeed (res) : when payment has succeed, returns result object
- error (error) : when payment has failed, returns  error object
-->

<template>
	<div id="payment-component" :class="{ 'payment-phone': $root.isPhone }">
		<div class="payment-title" v-html="$t('796')"></div>
		<form method="post" id="payment-form" @submit="onSubmit">
			<div class="form-row">
				<label for="payment-card"> </label>
				<div v-show="cardIsReady" id="payment-card">
					<!-- a payment Element will be inserted here. -->
				</div>
				<!-- Used to display Element errors -->
				<div v-if="error" id="payment-errors" class="payment-errors" role="alert">{{ error }}</div>
			</div>
			<button  type="submit" class="submit button-submit button-round button-style-1 button-blue payment-button" :disabled="!canPay">{{ this.$t("797") }}</button>
		</form>
	</div>
</template>

<script>
import PaymentService from "../../services/PaymentService";

export default {
	name: "PaymentComponent",
	props: ["product", "amount", "user"],
	components: {
	},
	data() {
		return {
			payment: PaymentService.stripe,
			card: null,
			cardOptions: {
				style: {
					base: {
						"iconColor": "#2D265C",
						"color": "#2D265C",
						"fontWeight": "500",
						"fontFamily": "inherit",
						"fontSize": "16px",
						"fontSmoothing": "antialiased",
						":-webkit-autofill": {
							color: "#2D265C",
						},
						"::placeholder": {
							color: "rgba(45, 38, 92, 0.6)",
						},
					},
					invalid: {
						iconColor: "#E30613",
						color: "#E30613",
					},
				},
				hidePostalCode: true,
			},
			canPay: false,
			cardIsReady: false,
			paymentInfos: null,
			
			phoneTranslations: {
				countrySelectorLabel: null,
				phoneNumberLabel: this.$t("659"),
			},
			phoneObject: {},
			error: null,
		};
	},

	computed: {
		
	},
	mounted(){
		console.log('PAYMENT COMPONENT mounted with payment ', this.payment, this.session)
	},	
	emits: ['succeed', 'error'],
	methods: {
		initPayment() {
			
			this.$store.commit('showAlert', 'wait');
			PaymentService.createPaymentIntent(this.product, this.amount, this.user)
				.then((response) => {
					if (response.ok) {
						return response.json();
					} else {
						throw error;
					}
				})
				.then((response) => {
					console.log('create payment response ', response)
					if (response && response.status == 1) {
						if (response.datas.isfree == 1) {
							this.$emit("succeed", { user : this.user, isfree: 1 });
						} else {
							this.paymentInfos = response.datas;
							this._createCardElement();
						}
						this.$store.commit('hideAlert')
					} else {
						if (response.status !== 1) {
							this.error = response.msg ? response.msg : response.datas.type + " : " + response.datas.raw.message;
							console.error(response);
							this.$store.commit('showAlert', {error : this.error})
						}
						
					}
				})
				.catch((error) => {
					this.$store.commit('showAlert', {error : error.message})
					console.error(error.message);
					this.error = error.message;
				});
		},
		_createCardElement() {
			console.log('create card elements', this.payment)
			this.card = this.payment.elements().create("card", this.cardOptions);
			this.card.mount("#payment-card");
			this.card.on("change", (event) => {
				// Disable the Pay button if there are no card details in the Element
				this.canPay = !event.empty && !event.error && event.complete;
				this.error = event.error ? event.error.message : "";
			});
			this.cardIsReady = true;
		},
		onValidatePhone(obj) {
			this.phoneObject = obj;
			this.userData.phone = this.phoneObject.formattedNumber;
		},

		async onSubmit(evt) {
			console.log('PAYMENT on submit')
			evt.preventDefault();
			this.$store.commit('showAlert', 'wait');
			PaymentService.payWithCard(this.card, this.paymentInfos.client_secret)
				.then(async(res) => {
					 console.log('success in payment ', res)
					if (res.error) {
						this.error = res.error.message;
						this.$emit("error", res.error);
					}
					if (res.paymentIntent && res.paymentIntent.status == "succeeded") {
						this.$emit("succeed", {user :this.user, payment : res.paymentIntent});
					}

					this.$store.commit('hideAlert');
					let isEnded = await PaymentService.endPayment(this.product, this.amount, this.user, res);
					console.log('paymenet ended ', isEnded)
					// console.log('send end payment quand même')
				})
				.catch((res) => {
					
					console.error("in catch error pay with card", res);
					this.error = res.error.message;
					this.$store.commit('showAlert', {error : this.error})
					this.$emit("error", res.error);
				});
		},
	},
};
</script>

<style scoped lang="scss">
@import "./src/css/sass/variables";
#payment-component {
	padding: 1.5rem;
	margin-bottom: 1rem;
	border: 1px solid $grey;
	border-radius: $border-radius;
	text-align: center;
	form {
		width: 100%;
	}
	.payment-user-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.payment-user {
		width: calc(50% - 0.5rem);
		display: inline-block;
		margin-bottom: 1.5rem;
		&:nth-child(odd) {
			margin-right: 0.5rem;
		}
		&:nth-child(even) {
			margin-left: 0.5rem;
		}
	}
	.payment-user label {
		display: block;
		margin-bottom: 0.5rem;
	}

	.payment-user input {
		font-size: 16px;
		margin-bottom: 0.5rem;
		padding: 0.5rem;
		width: calc(100% - 1rem);
	}

	.payment-title {
			font-weight: bold;
			font-size: 1.5rem;
			margin-bottom: 1rem;
		
		
	}
	#payment-card,
	.form-row > input {
		font-family: "Poppins";
		padding: 0.5rem;
		border: 1px solid rgba(66, 66, 66, 0.17);
		border-radius: 4px;
	}

	#payment-card{
		margin-bottom:1rem;
	}

	.payment-user.invalid input {
		border-color: red;
	}
	.payment-errors {
		padding: 1rem 0;
		color: red;
		font-size: 1.2rem;
		text-align: center;
	}

	.payment-button {
		margin: 0 auto;
	}

	.payment-info {
		line-height: 1.1;
		font-style: italic;
		font-size: 1rem;
	}

	&.payment-phone {
		.payment-user {
			&#payment-date,
			&#payment-phone {
				width: 100%;
			}
		}
	}
}
</style>
