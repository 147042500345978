/**
 * INPUT FIELD COMPONENT
 * check its content, and emit
 * --> 'isValid', true if is valid -->  this.$emit('isValid', true, this.name, this.inputValue)
 * --> 'isValid', false if is valid -->  this.$emit('isValid', false, this.name, this.inputValue)
 * @example <input-checkbox-component v-if="input.type==='checkbox'" :input="input" :index="index" v-on:isValid="isInputValid"></input-checkbox-component>
 */

export default {
    name: 'inputCheckboxComponent',
    props: [
        'input', 'index'
    ],
    components: {
    },
    data () {
        return {
            inputValue: this.input.value,
            alertDisplayed: false,
            className: '',
            hasIcon: this.input.icon !== undefined ? '' : 'no-icon',
            isValid : false
        }
    },
    mounted: function () {
        //console.log('input checkbox ', this.input)
        if (this.input.mustSaveFirstValue) {
            this.$emit('isValid', true, this.input.name, this.inputValue, this.index);
        }
    },
    emits: ['isValid'],
    methods: {
        _sendValidation(bool, name, value) {
            //console.log('V -- INPUT CHECKBOX COMPONENT : input is valid ? ', bool, name, value);
            this.displayAlert(bool, value);
            this.$emit('isValid', bool, name, value, this.index);
        },

        validCheckBox() {

            let value = this.inputValue ? 1 : 0,
                bool = this.input.required ? this.inputValue : true; // useful, valid or invalid input if checkbox is required and not checked. If input not required, is always valid
            this.isValid = bool;
            this.displayAlert(bool, value);
            this.$emit('isValid', bool, this.input.name, value);
        },

        displayAlert(bool) {
            this.alertDisplayed = bool ? undefined : this.input.alert;
            //this.className = bool ? 'valid' : 'invalid';
            if (!bool) {
                this.alertDisplayed = undefined;
                this.className = this.input.required ? 'invalid' : undefined;
            }
        }
    }
}