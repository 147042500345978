/**
 * @example <button-component id="id to give to button" bt-class="class to add to button" v-on:buttonClick="onClickCallback" text="The text to display"></button-component>
 * on click, emit a 'buttonClick' event to parent
 */
//import FontAwesomeIcon from '@fortawesome/vue-fontawesome';

export default {
    name: 'buttonComponent',
    components: {
       // FontAwesomeIcon
    },
    props: [
        'text', 'btClass', 'id', 'type', 'icon'
    ],
    data () {
        return {
            displayClass: 'button-component ' + this.btClass,
        }
    },
    mounted: function () {
        console.log('BUTTON ' + this.id+ ' MOUNTED WITH ICON ', this.icon)
    },
    emits: ['buttonClick'],
    methods: {
        onClick: function (event) {
            event.preventDefault();
            event.stopPropagation();
            this.$emit('buttonClick', this.id, event)
        }
    }
}