<template lang="html">
<transition name="fade">
<div id="message-viewer-component"  v-show="showWait || show" class="message-viewer-component component" >
    <button v-if="!showWait" class="button button-close" @click="resetMessage"><inline-svg :src="closeIcon"></inline-svg></button>
    <p class="message-error" v-if="displayedMessage.error !== undefined" v-html="displayedMessage.error"></p>
   <p class="message-confirm"  v-if="displayedMessage.confirm !== undefined" v-html="message.confirm"></p>
    <p v-show="showWait" id="message-spinner" class="message-wait"></p>
    </div> 
</transition>
</template>

<script src="./messageViewer-component.js"></script>

<style scoped src="./messageViewer-component.css">

</style>

