import formComponent from '@components/form/form-component.vue';
import UserService from '@services/UserService';

export default {
    name: 'passwordResetPageComponent',
    components: {
        formComponent,
    },
    props: [
        'cordovaIsActive'
    ],
    data() {
        return {
            type: this.$root.urlParams.pageType,
            resetForm: {
                hasCaptcha: false,
                isCaptchaVisible: true,
                inputs: [{
                    name: "password",
                    placeholder: this.$t('180'),
                    label: this.$t('389'),
                    type: "password",
                    regex: /^(?=(.*\d){1})(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,}/,
                    style: 'input',
                    required: true,
                    alert: this.$t('90'),
                    visible: true
                }],
                buttons: [{
                    text: this.$t('87'),
                    id: "form-submit",
                    class: "button-submit button-round button-style-1 button-blue",
                    disabled: true,
                    visible: true
                }],
                mustDisableOnSubmit : true
            }

        }
    },
    destroyed: function () {
        this.$store.commit('hideAlert');
    },
    methods: {
        /**
         * @description send new reseted password to server
         * @param {object} formDatas datas object from form
         */
        sendPassword: function (formDatas) {
            console.log('V -- PASSWORD RESET PAGE : send datas ', formDatas)
            this.$store.commit('showAlert', 'wait');
            console.log(this.resetForm.buttons[0])
            this.resetForm.buttons[0].disabled = true;
            UserService.resetUserPassword(formDatas, this.$route.query.user)
                .then(user => {
                    this.$router.push({
                        name: 'login',
                        params: {
                            displayAlert: this.$t('88')
                        }
                    });
                })
                .catch(error => {
                    console.error(error)
                    this.$store.commit('showAlert', {
                        error: error
                    });
                })
        }
    }
}