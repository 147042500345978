<template lang="html">
    <button :key="icon !== undefined ? icon.iconName : ''"  :id="id" :class="displayClass"  :type="type !== undefined ? type : 'button'" v-on:click="onClick($event)">
        <span>{{text}}</span>
       <inline-svg v-if="icon" :src="icon"></inline-svg>
    </button>
</template>

<script src="./button-component.js"></script>

<style scoped src="./button-component.css">

</style>

