import mainPageComponent from '@/js/pages/mainPage/mainPage-component.vue';
import loginPageComponent from '@/js/pages/loginPage/loginPage-component.vue';
import registerPageComponent from '@/js/pages/registerPage/registerPage-component.vue'
import passwordResetPageComponent from '@/js/pages/passwordResetPage/passwordResetPage-component.vue';
import passwordPageComponent from '@/js/pages/passwordPage/passwordPage-component.vue';
import confirmationPage from '@/js/pages/confirmationPage/confirmationPage-component.vue'

import store from '@/js/store/store';

let routes = [

    {
        path: '/',
        name: 'home',
        redirect: { path: "/login" },
        component: mainPageComponent,
        children: [
            {
                path: '/login',
                name: 'login',
                component: loginPageComponent
            },
            {
                path: '/register',
                name: 'register',
                component: registerPageComponent
            },
            {
                path: '/password',
                name: 'password',
                component: passwordPageComponent,
                props: {
                    isAuth: store.getters.getIsAuth
                }
            },
            {
                path: '/passwordReset',
                name: 'passwordReset',
                component: passwordResetPageComponent
            },
            {
                path: '/confirmation',
                name: 'confirmation',
                component: confirmationPage
            }
        ]
    }
];

export default routes;