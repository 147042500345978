
//import linkedinLoginButtonComponent from "@components/login/linkedin-login-button/linkedin-login-button-component.vue";
import formComponent from "@components/form/form-component.vue";
import buttonComponent from "@components/others/button/button-component.vue";
import Tools from "@/js/utils/tools";
import UserService from "@services/UserService";
import ShopService from "@services/ShopService";
import PracticalSession from "@components/practical-session/practical-session.vue"
import PaymentComponent from '@components/payment-component/payment-component.vue';
import _find from "lodash/find";
export default {
	name: "registerPage",
	components: {
		//linkedinLoginButtonComponent,
		formComponent,
		buttonComponent,
        PracticalSession,
		PaymentComponent
	},
	props: ["organization"],
	data() {
		return {
			requiredLegend: this.$t("36"),
			step: 1, // first register, then pass to email screen
			userToCheck: {
				login: undefined,
				mdp: undefined,
			},
			registerButtons: {
				facebook: {
					text: this.$t("367"),
					disabled: this.organization !== undefined ? true : false,
				},
				google: {
					text: this.$t("368"),
					disabled: this.organization !== undefined ? true : false,
				},
				linkedin: {
					text: this.$t("369"),
					disabled: this.organization !== undefined ? true : false,
				},
				mail: {
					text: this.$t("370"),
					disabled: this.organization !== undefined ? true : false,
				},
			},
			form: {
				hasCaptcha: !this.$root.isMobile && this.prefilledMail == null && this.organization == undefined,
				isCaptchaVisible: false,

				inputs: [
					{
						name: "promocode",
						label: this.organization !== undefined ? this.$t("539") : this.$t("289"),
						//info: this.$t('43'),
						alert: this.$t("337"),
						autocomplete: "off",
						placeholder: this.$t("383"),
						type: "text",
						readonly: undefined,
						//required: this.organization !== undefined ? true : false,
						required: this.organization !== undefined ? (this.prefilledMail || this.prefilledPromocode ? false : true) : false,
						value: "",
						style: "input",
						visible: true,
					},

					{
						name: "email",
						domain: this.organization !== undefined && this.organization.registrationdomain ? this.organization.registrationurl : null,
						//placeholder: this.$t('179'),
						label: this.$t("492"),
						type: "email",
						autocomplete: "off",
						value: "",
						//alert: this.$t('69'),
						required: true,
						showRequired: true,
						isValid: false,
						style: "input",
						visible: false,
					},
					{
						name: "password",
						label: this.$t("493"),
						alert: this.$t("40"),
						placeholder: "",
						autocomplete: "off",
						type: "password",
						required: true,
						style: "input",
						regex: /^(?=(.*\d){1})(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,}/,
						visible: false,
					},
					/*{
                        name: "newsletter",
                        //label: this.$t('57'),
                        //info: this.$t('58'),
                        checkText: this.$t('366'),
                        mustSaveFirstValue: true,
                        placeholder: "",
                        value: this.$root.tempConfirmDatas.newsletter !== undefined ? this.$root.tempConfirmDatas.newsletter : 0,
                        type: "checkbox",
                        required: false,
                        style: 'input',
                        visible: true
                    },*/

					/*
                    {
                        name: "department",
                        //autocomplete: "department",
                        autocomplete: false,
                        label: this.$t('261'),
                        info: this.$t('256'),
                        required: true,
                        style: 'select',
                        value : 1,
                        outputId : true,
                        options: departments
                    },
                      {
                        name: "cgvu",
                        label: this.$t('59'),
                        checkText: this.$t('183'),
                        placeholder: "",
                        type: "checkbox",
                        required: true,
                        style: 'input'
                    }*/
				],
				buttons: [
					{
						text: this.$t("379"),
						id: "form-submit",
						class: "submit button-submit button-round button-style-1 button-blue",
						disabled: true,
						visible: false,
					},
				],
			},
			optionalInputs: {
				name: {
					name: "name",
					label: this.$t("580"),
					alert: this.$t("584"),
					autocomplete: "off",
					//placeholder: this.$t('383'),
					type: "text",
					readonly: undefined,
					required: true,
					value: "",
					style: "input",
					visible: false,
				},
				lastName: {
					name: "lastName",
					label: this.$t("581"),
					alert: this.$t("585"),
					autocomplete: "off",
					//placeholder: this.$t('383'),
					type: "text",
					readonly: undefined,
					required: true,
					value: "",
					style: "input",
					visible: false,
				},
				birthDate: {
					name: "birthDate",
					label: this.$t("582"),
					// alert: this.$t('337'),
					autocomplete: "off",
					//placeholder: this.$t('383'),
					type: "date",
					readonly: undefined,
					required: true,
					value:"",
					style: "input",
					visible: false,
				},
				numMember: {
					name: "numMember",
					label: this.$t("583"),
					alert: this.$t("587"),
					autocomplete: "off",
					//placeholder: this.$t('383'),
					type: "text",
					readonly: undefined,
					required: true,
					value: "",
					style: "input",
					visible: false,
				},
				phone: {
					name: "phone",
					label: this.$t("657"),
					alert: this.$t("660"),
					autocomplete: "off",
					// placeholder: this.$t('659'),
					type: "phone",
					readonly: undefined,
					required: true,
					value: "",
					style: "input",
					visible: false,
				},
				group: {
					name: "group",
					label: this.$t("291"),
					alert: this.$t("647"),
					autocomplete: "off",
					//placeholder: this.$t('383'),
					type: "text",
					readonly: undefined,
					required: true,
					value: this.$route.query.group || "",
					style: "input",
					visible: false,
				},
			},
			registerMethod: undefined,
			helpIcon: this.$root.imgUrl + "icon-help.svg",
			infoIcon: this.$root.imgUrl + "icon-info.svg",
			// isPrefilled: 0,
			isConfirmation: 0,
			practicalTrainingInfos: null,
			practicalTraining: null,
			fromPractical: null,
			activationCode: null,
			isRegistering: false,
			isPaying : false,
			registeredUser : {
				name : '',
				lastName : '',
				birthDate : '',
				phone : ''
			}
		};
	},
	computed: {
		title: function() {
			if (this.step == 1) {
				return this.fromPractical && !this.isConfirmation ? this.$t("673") : this.$t("364");
			} else {
				return this.$t("542");
			}
		},
		welcomeMessage: function() {
			let text = this.$t("365");
			if (this.fromPractical && !this.isConfirmation) {
				text = this.$t("672");
			} else if (this.organization !== undefined) {
				text = this.organization.welcometxt;
			}

			return text;
		},
		
	},

	beforeMount: function() {
		this.prefilledMail = Tools.getURLParameter(window.location, "mail");
		this.prefilledPromocode = Tools.getURLParameter(window.location, "promocode");
		this.prefilledNumMember = Tools.getURLParameter(window.location, "matricule");
		//this.isPrefilled = this.hasParameter('filled')
		this.isConfirmation = this.hasParameter("confirm") == 1 ? 1 : 0;
		this.activationCode = Tools.getURLParameter(window.location, "code");
		this.fromPractical = Tools.getURLParameter(window.location, "from") == "practical" ;

		if (this.isConfirmation) {
			this.form.inputs[0].visible = false;
			this.form.inputs[0].required = false;

		
		}

		// console.log('is prefilled ', this.isPrefilled)
		//console.log('has prefilled mail', this.prefilledMail)
		console.log("--REGISTER PAGE-- has prefilled promocode", this.prefilledPromocode);
		console.log("--REGISTER PAGE-- has prefilled num mber", this.prefilledNumMember);
		console.log('--REGISTER PAGE-- is confirmation ', this.activationCode)
		console.log("--REGISTER PAGE-- organization ", this.organization);
		console.log("--REGISTER PAGE-- fromPractical ", this.fromPractical);
		//console.log('this.prefilledMail || this.prefilledPromocode ', this.prefilledMail || this.prefilledPromocode)
		if (this.organization == undefined) {
			this.unlockButtons(true);
		}
		if (this.prefilledMail) {
			this.prefillMail();
		}
		if (this.prefilledPromocode) {
			this.prefillPromocode();
		}
		
	

		//s'il ya  des champs optionnels, on les ajoute aux inputs
		if (this.organization && this.organization.fields !== undefined && !this.fromPractical && !this.isConfirmation) {
			console.log("--REGISTER PAGE-- organization fields", this.organization.fields);
			for (let [name, toShow] of Object.entries(this.organization.fields).reverse()) {
				console.log("--REGISTER PAGE-- optionnal field name", name, "is to show ? ", toShow);
				
				if (toShow) this.form.inputs.splice(1, 0, this.optionalInputs[name]);
			}
		}
		
		//update captcha
		this.form.hasCaptcha = !this.$root.isMobile && !this.prefilledMail && this.organization == undefined;

		if (this.fromPractical && !this.isConfirmation) {
			this._initFromPractical();
		}

		if(this.prefilledNumMember && this.organization.fields['numMember']){
			this.prefillNumMember()
		}
		
	},
	mounted: function() {
		console.log(
			"--REGISTER PAGE-- register page mounted with prefilledMail ",
			this.prefilledMail, this.form
		);
		// console.log('register page mounted with cgvu ', this.$root.cgvu)
		this.showMailForm();

		this.$root.scrollToTop();
	},
	methods: {

		_initFromPractical(){

				//console.log('--REGISTER PAGE-- init from practical')
					//le password n'est plus requis
				this.form.inputs[2].required = false;

				//on change le label du champ email
				this.form.inputs[1].label = this.$t('448')

				//on changhe le texte du bouton
				 this.form.buttons[0].text = this.$t('679')
				 if(this.mustPay) this.form.buttons[0].visible = false;

				//on change l'initialisation de la date aux années 60
				this.optionalInputs['birthDate'].openDate = new Date('January 01, 1970 00:01:00')

				//on ajoute les champs optionnels
				const optionnalInputsToUnlock = ["name", "lastName", "phone", "birthDate"];

				//console.log('from organization choices', this.organization.fields)
				
				for (let field of optionnalInputsToUnlock) {
					this.form.inputs.push(this.optionalInputs[field]);
					this.form.inputs.find((input) => input.name == field).visible = true;
					this.form.inputs.find((input) => input.name == field).required = true;
					this.form.inputs.find((input) => input.name == field).readonly = false;
				}

				if(this.organization.fields && this.organization.fields.group){
					this.form.inputs.push(this.optionalInputs['group']);
				}

				//on récupère les infos sur la session de formatin pratique
				this.practicalTrainingInfos = {
					id: Tools.getURLParameter(window.location, "ptId"),
					discount: Tools.getURLParameter(window.location, "ptDiscount"),
					price : Tools.getURLParameter(window.location, "ptPrice"),
				};

				//console.log('infos ', this.practicalTrainingInfos)
				console.log("practical training is ", this.$store);
				this.practicalTraining = this.$store.getters.getDatas.practicalTrainings.find((session) => session.id == this.practicalTrainingInfos.id);
			
		
			console.log("practical training is ", this.practicalTraining);

			console.log('--REGISTER PAGE-- form inputs', this.form.inputs, this.form.buttons)
		},
	

		/**
		 * show full inputs
		 */
		showMailForm() {
			console.log("--REGISTER PAGE--  show mail form is confirmation ", this.isConfirmation);
			let inputsToUnlock;
			this.registerMethod = "email";

			if(this.fromPractical){
				if(this.isConfirmation){
					inputsToUnlock = ["email", "password"]
				} else {
					inputsToUnlock = ["email", "group"]
				}
				
			} else {
				inputsToUnlock = ["email", "password"];
			}
			
			
			if (this.organization !== undefined && !this.fromPractical && !this.isConfirmation) {
				for (let field in this.organization.fields) {
					//console.log(field, this.organization.fields[field]);

					if (this.organization.fields[field]) inputsToUnlock.push(field);
				}
			}

			//make inputs password / email visibles
			for (let input of this.form.inputs) {
				if (inputsToUnlock.includes(input.name)) {
					//on masque le champ numMber si il y a un matricule dans l'url
					if(this.prefilledNumMember && input.name == 'numMember'){
						input.visible = false;
					} else {
						input.visible = true;
					}
					
				
				} 
			}

			//make button visible
			this.form.buttons[0].visible = true;

			//make captcha visible
			this.form.isCaptchaVisible = true;
		},

		/**
		 * @description send email to user who want to register
		 * @param {object} userDatas
		 */
		register: function(datas) {
		
			if (!this.isRegistering) {
				// avoid double click
				this.isRegistering = true;

				console.log("--REGISTER PAGE-- register user", datas);
				//console.log('V -- register once', this.form.buttons[0].once);
				//console.log('V -- in register user promocode ', this.$root.tempConfirmDatas.promocode);
				// console.log('V -- in register user confirmation ', this.isConfirmation);

				this.$store.commit("showAlert", "wait");

				datas.origin = this.$root.urlParams.origin;
				datas.from = "register";
				datas.cgvu = 1; // acceptation d'office des cgvu
				datas.ptId = this.practicalTrainingInfos ? this.practicalTrainingInfos.id : null;
				datas.ptDiscount = this.practicalTrainingInfos ? this.practicalTrainingInfos.discount : null;
				datas.ptPrice = this.practicalTrainingInfos ? this.practicalTrainingInfos.discpriceount : null;


				//add promocode if coming from socials registering
				if (datas.loginApi !== undefined && datas.loginApi !== "form") {
					datas.promocode = this.$root.tempConfirmDatas.promocode;
					datas.login = datas.name;
				} else if (this.prefilledPromocode) {
					datas.promocode = this.$root.tempConfirmDatas.promocode;
				} else if (this.fromPractical == "practical") {
					datas.promocode = this.$root.tempConfirmDatas.promocode;
				}
				if(this.prefilledNumMember){
					datas.numMember = this.$root.tempConfirmDatas.numMember;
				}

				//add account id
				datas.account = this.$root.urlParams.account;
				console.log('--REGISTER PAGE-- is there a promocode ? ', datas.promocode, this.$root.tempConfirmDatas.promocode  )
				console.log('--REGISTER PAGE-- on vient d\'un email à confirmer ? ',this.isConfirmation , this.prefillMail , (this.isConfirmation && this.prefillMail)  )
				
				if (this.isConfirmation && this.prefillMail) {
					//SI ON VIENT D'UN EMAIL A CONFIRMER AVEC CHAMP EMAIL PREREMPL

					UserService.confirmUser(datas, this.activationCode)
						.then((registeredUser) => {
							this.onRegistered(registeredUser, datas.promocode);
						})
						.catch((error) => {
							console.warn("error in register ", error);
							this.$store.commit("showAlert", {
								error: error,
							});
							this.isRegistering = false;
						});

					//si orga ou formation pratique
				} else  {


					const checkAndRegisterUser = (datas) =>{
						//check login
						UserService.checkIfLoginIsAvailable(datas.loginApi && datas.loginApi !== "form" ? datas.login : datas.email)
						.then((response) => {
							let formattedUser = UserService.getFormattedUser(datas);

							//console.log("organization ", this.organization);
							if (this.organization !== undefined && this.organization !== null) {
								formattedUser.organization = this.organization;
							}

							formattedUser.fromPractical = this.fromPractical
							formattedUser.ptId = this.practicalTrainingInfos ? this.practicalTrainingInfos.id : null;
							formattedUser.ptDiscount = this.practicalTrainingInfos ? this.practicalTrainingInfos.discount : null;
							formattedUser.ptPrice = this.practicalTrainingInfos ? this.practicalTrainingInfos.price : null;

							//console.log(response)
							console.log("datas sended to register ", formattedUser);

							UserService.registerUser(formattedUser, this.isConfirmation)
								.then((registeredUser) => {
									this.onRegistered(registeredUser, datas.promocode);
								})
								.catch((error) => {
									console.warn("REGISTER PAGE regsiterUser error ", error);
									this.$store.commit("showAlert", {
										error: error,
									});
									this.isRegistering = false;
								});
						})
						.catch((error) => {
							console.warn("REGISTER PAGE checkIfLoginIsAvailable error ", error);
							error = (error !== undefined && error !== null) ? error : this.$t("101");
							this.$store.commit("showAlert", {
								error: error,
							});
							this.isRegistering = false;
						});
					}

					//si promocode
					if (datas.promocode.length > 0) {
						//checkPromocode
						ShopService.checkPromocode(datas.promocode)
						.then((response) => {
							console.log("promocode validated", response.promocode);
							console.log("promocode uniq ", response.promocode.uniq);

							//get promocode id
							if (response.promocode !== undefined) {
								datas.promocodeid = response.promocode.id;
								datas.uniq = response.promocode.uniq;
							} else {
								datas.promocodeid = -1;
							}

							//console.log(datas)

							checkAndRegisterUser(datas);
						})
						.catch((err) => {
							console.warn("promocode not valid", err);
							this.isRegistering = false;

							if (!this.prefilledPromocode) {
								this.invalidPromocodeInput();
							}

							this.$store.commit("showAlert", {
								error: err.msg,
							});
						}); 
					} else {
						//pour les autres cas, pas de check promocode
						checkAndRegisterUser(datas);
					}
					
						

					
				}
			}
		},

		/**
		 * when register is donc
		 * @param {boject} registeredUser user registered returned by BO
		 */
		async onRegistered(registeredUser, hasPromocode) {
			this.registeredUser = registeredUser
			 console.log('--REGISTER PAGE-- on registered with registered user', this.registeredUser, hasPromocode)
			//console.log('register API method is ', registeredUser.loginApi)
			this.$store.commit("hideAlert");

			//si on vient de la formation pratique
			if (this.fromPractical && !this.isConfirmation) {

				if (this.practicalTrainingInfos.price > 0){
					console.log('on doit payer ')
					this.isPaying = true;
					this.$nextTick(async()=>{
						console.log(this.$refs['payment-comp'])
						let isPaid = await this.$refs['payment-comp'].initPayment();
						console.log('is paid ', isPaid)
						// payment component automatically send onPaid event after paiment
					})
				} else{
					this.$router.push({ name: "confirmation", query: { ...this.$route.query, group : registeredUser.group } });
				}
				
				
			}
			//si promocode, autoconnexion
			else if (!this.fromPractical || hasPromocode || this.organization !== undefined) {
				console.log('has promocode')
				this.$root.goToApp("trainings", {
					user: this.registeredUser,
					userStatus: "logged",
				});
				

			//si pas de promocode, étape 2 (mail de confirmation)
			} else {
				console.log('autre ')
				this.step = 2;
			}
		},

		sendMail() {
			//console.log('renovyer le mail')
		},

		/**
		 * Keep temporary datas when changing form inputs
		 * @param {string} name name of changed input
		 * @param {*} value value of changed input
		 */
		keepTempDatas(name, value) {
			console.log('--REGISTER PAGE-- change input ',name, value, this.fromPractical);

			let input = _find(this.form.inputs, (input) => {
				return input.name === name;
			});

			this.$root.tempConfirmDatas[name] = value;

			if (input.name === "promocode" && this.organization !== undefined) {
				//console.log('input promocode', input.isValid)
				this.unlockButtons(input.isValid);
			}

			console.log('temps datas', this.$root.tempConfirmDatas)
		},

		/**
		 * unlock register buttons if promocode is valid
		 * @param {boolean} bool to lock or unlock buttons
		 */
		unlockButtons(bool) {
			//console.log('unlock buttons ', bool)
			for (let buttonName in this.registerButtons) {
				this.registerButtons[buttonName].disabled = !bool;
			}
		},

		invalidPromocodeInput() {
			let promocodeEl = document.getElementById("input-promocode");
			promocodeEl.classList.remove("valid");
			promocodeEl.classList.add("invalid");
		},

		/**
		 * if coming from a valid url, prefill email
		 */
		prefillMail() {
			console.log("--REGISTER PAGE-- in prefill mail fn,  mail is", this.prefilledMail);
			let inputMail;
			for (let input of this.form.inputs) {
				if (input.name == "email") {
					input.value = this.prefilledMail;
					input.disabled = true;
					input.readonly = true;
					input.label = this.$t("494");
					this.keepTempDatas("email", this.prefilledMail);
					this.showMailForm();
				}
			}
		},
		/**
		 *a numMber in url, prefill it
		 */
		prefillNumMember() {
			console.log("--REGISTER PAGE-- in prefill num mber,  mail is", this.prefilledNumMember, this.form.inputs)
			
			for (let input of this.form.inputs) {
				if (input.name == "numMember") {
					input.value = this.prefilledNumMember;
					input.disabled = true;
					input.visible = false;
					input.readonly = true;
					input.required = false;
					this.keepTempDatas("numMember", this.prefilledNumMember);
					
				}
			}
		},

		prefillPromocode() {
			console.log("in  promocode, promocode is", this.prefilledPromocode);
			this.form.inputs[0].value = this.prefilledPromocode;
			this.form.inputs[0].disabled = true;
			this.form.inputs[0].readonly = true;
			this.form.inputs[0].required = false;
			this.form.inputs[0].visible = false;
			this.keepTempDatas("promocode", this.prefilledPromocode);
		},
		

		/**
		 * if url has parameter
		 */
		hasParameter(name) {
			return Tools.getURLParameter(window.location, name) ? 1 : 0;
		},

		onPaid(result){
			//this.step = 2;
			console.log('REGSITER PAGE on paid', result)
			this.$router.push({ name: "confirmation", query: { ...this.$route.query, group : result.user.group } });
			
		},
		onPaidError(e){
			this.$store.commit('showAlert', {error : e})
			console.log('REGSITER PAGE on paid error', e)
		}

	},
};
