import buttonBackComponent from '@components/others/buttonBack/buttonBack-component.vue';


export default {
    name: 'home',
    components: {
        buttonBackComponent
    },

    data() {
        return {
            logo: 'assets/img/Salvum.svg',
            helpIcon: this.$root.imgUrl + 'icon-help.svg',
            registerFooter: {
                text: this.$t('374'),
                link: this.$t('253'),
                url: 'login'
            },
            loginFooter: {
                text: this.$t('331'),
                link: this.$t('380'),
                url: 'register'
            }
        }
    },
   
   /* beforeRouteEnter(to, from, next) {
        console.log('BEFORE ENTER MAIN ', to.name, from.name)
        if (to.name === 'home') {
           // next('login');
        } else {
            next();
        }

    },*/
    computed: {
        organization: function () {
            return this.$store.getters.getDatas.organization;
        },
        background (){
            console.log('background', this.organization, this.$root.imgUrl)
            if(this.organization && this.organization.image1x && this.organization.image1x !== '' && this.organization.image2x !== ''){
               // console.log('back orga')
                return this.organization.image2x;
            } else {
                console.log('back root',  this.$root.imgUrl + 'back-register@2x.jpg')
                return this.$root.imgUrl + 'back-register@2x.jpg';
            }
            
        },
        background1x (){
            
            if(this.organization &&  this.organization.image1x && this.organization.image1x !== '' ){
                return this.organization.image1x;
            } else {
                return this.$root.imgUrl + 'back-register.jpg';
            }
            
        },
        background2x (){
            if(this.organization &&  this.organization.image2x !== ''){
                return this.organization.image2x;
            } else {
                return this.$root.imgUrl + 'back-register@2x.jpg';
            }
            
        },
        footer() {
            if (this.$route.name == 'login' || this.$route.name == 'password' || this.$route.name == 'passwordReset' ) {
                return this.loginFooter
            } else if (this.$route.name == 'register' && this.$route.query.from !== 'practical') {
                return this.registerFooter
            } else {
                return null;
            }
        }

    },
    created: function () {
        //console.log('V -- LOGIN PAGE CREATED get user in storage : ', UserService.getStorageUser())
        /*if(!this.cordovaIsActive){
            //check if api are already logged
            //this.checkApiStatus();
        } */
    },

    methods: {

        //ROUTING
        goToFaq() {
            window.open(this.$root.config.serverConfig.helpLink)
        },
        goToSalvum() {
            window.open(this.$root.config.serverConfig.siteLink)
        },

    }
}