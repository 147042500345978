<template lang="html">
  <div :class="'form-input form-input-checkbox ' + className + ' '+ hasIcon">
    <div class="form-input-content">
      <label v-if="input.label !== undefined && input.label !== ''">{{input.label}}<!--span class="input-required" v-if="input.required">*</span--></label>
        <p class="form-input-info" v-if="input.info !== undefined">{{input.info}}</p>
        
        
        <input  :class="inputValue ? 'checked' : ''" :disabled="input.disabled" :id="index !==undefined ? 'form-'+index : undefined" :required="input.required"  :name="input.name" :placeholder="input.placeholder" type="checkbox"  v-model="inputValue"  v-on:change="validCheckBox()">
        <label :for="index !==undefined ? 'form-'+index : undefined"  class="custom-check">{{input.checkText}}</label>
         <p v-if="alertDisplayed" class="input-alert">{{input.alert}}</p>
    </div>
  </div>
</template>

<script src="./input-checkbox-component.js"></script>

<style scoped src="./input-checkbox-component.css"></style>

