<template lang="html">
    <div id="confirmation-page" class=" dynamic-page" >
        <h3 v-html="$t('674')"></h3>

        <PracticalSession :session="practicalTraining" :discount="practicalTrainingInfos.discount" :organization="organization" />
        <p v-html="$t('675')"></p>

        <div class="buttons">
             <button  type="button" class="button form-button button-submit button-round button-style-1 button-blue"  @click="goToRegister">{{$t('676')}}</button>
             <button  type="button" class="button form-button button-submit button-round button-style-1 button-blue"  @click="goToPractical">{{$t('677')}}</button>
        </div>
    </div>
</template>

<script src="./confirmationPage-component.js"></script>


