<template lang="html">
  <div :class="'form-input form-input-email ' + className + ' '+hasIcon + ' ' + ((inputValue !== undefined && inputValue.length > 0) ? (isValid ? 'valid' : 'invalid') : '') ">
    
    <div :class="'form-input-content '+(focus ? 'focus' : 'no-focus')">
      <label :for="index !==undefined ? 'form-'+index : undefined" v-if="input.label !== undefined && input.label !== ''">{{input.label}}<!--span class="input-required" v-if="input.required && input.showRequired">*</span--></label>
      <p class="form-input-info" v-if="input.info !== undefined">{{input.info}}</p>
      <p v-if="alertDisplayed"  class="input-alert">{{input.alert}}</p>
      <div class="input-group">
      <input  @click="deletePlaceholder" autocapitalize="off" @focusin="onFocus(true)" @focusout="onFocus(false)" :readonly="input.readonly" :autocomplete="input.autocomplete" :disabled="input.disabled" :id="index !==undefined ? 'form-'+index : undefined" :required="input.required"  :name="input.name" :placeholder="placeholder" type="email"  v-model="inputValue" v-on:input="validInput">
      <div v-if="input.domain" class="input-group-append">
        <span class="input-group-text" id="basic-addon2">@{{input.domain}}</span>
      </div>
      </div>
    </div>
  </div>
</template>

<script src="./input-email-component.js"></script>

<style scoped src="./input-email-component.css"></style>

