/**
 * manage all states of users & users groups
 * available in vue by calling `store.user` (in main vue) or `this.$store.state.user`in Component
 * to commit : this.store.commit('...');
 */

import _set from 'lodash/set'; // to use nested properties of object

export default {
    state: {
        actualUser: {},
        isAuth : false,
        userActualNotification : undefined,
        ownedTrainings : []
    },
    getters: {
        getActualUser: (state) => {
            //console.log('__USER STORE : get actual user', state.actualUser);
            return state.actualUser;
        },
        getIsAuth : state =>{
            //console.log('__USER STORE : get  user is auth', state.isAuth);
            return state.isAuth;
        },
        
        getUserActualNotification : (state)=>{
            return state.userActualNotification;
        },
     
        getOwnedTrainings : (state) =>{
            //console.log('__USER STORE : get  owned trainings', state.ownedTrainings);
            
            
            return state.ownedTrainings;
        }
        
    },
    mutations: {
        setIsAuth : (state, isAuth) =>{
            state.isAuth = isAuth;
            //console.log('__USER STORE : set  user is auth', state.isAuth);
        },
        
        setActualUser(state, user) {
            state.actualUser = user;
            //console.log('__USER STORE : set actual user ', state.actualUser);
        },
        setUserData(state, { prop, value }) {
            //console.log('__USER STORE : set user data ' + prop + ' -> ' + value + '', state.actualUser);
            _set(state.actualUser, prop, value);
        },
        
        setUserActualNotification : (state, message)=>{
            state.userActualNotification = message;
        },
        connectUser(state, user) {
            state.actualUser = user;
            state.isAuth = true;
            //console.log('__USER STORE : user connected ', user);
        },
        deconnectUser(state) {
            state.actualUser = {};
            state.isAuth = false;
            //console.log('__USER STORE : user deconnected');
        },
        setOwnedTrainings(state){
            for(let training in state.actualUser.trainings){
                //console.log(training)
                //console.log(training.id)
                state.ownedTrainings.push(training.id)
            }
            //console.log('__USER STORE : user set owned trainings', state.ownedTrainings);
        }


    }, 
    actions : {
        loginUser({commit}, userDatas) {
            //console.log('__USER STORE : login User ', userDatas)
            //commit('setIsAuthWithFB', userDatas.loginApi === 'facebook' ? true : false );
            //commit('setIsAuthWithGoogle', userDatas.loginApi === 'google'? true : false);
            //commit('setIsAuthWithForm', userDatas.loginApi === 'form'? true : false);
            commit('setIsAuth', true);
            commit('setActualUser', userDatas);
        },
        logoutUser({commit}) {
            //commit('setIsAuthWithGoogle', false);
            //commit('setIsAuthWithFB', false);
            //commit('setIsAuthWithForm', false);
            commit('setIsAuth', false);
            commit('setActualUser', {});
        }
    }
}