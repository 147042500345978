<template lang="html">
	<div id="login-page" class="dynamic-page" >

    	<h3 v-html="$t('381')"></h3>
    	<div class="welcome-message" v-if="welcomeMessage" v-html="welcomeMessage"></div>
    	<form-component  :form="form" v-on:submitForm="checkIfUserExist" v-on:forgotPassword="resetPassword"></form-component>
		<div  :class="'cgvu ' + ($root.cgvu ? '' : 'hidden')" >{{$t('789')}}
			 <button type="button" class="btn btn-cgu" @click="$root.openCGVU($event)" >{{$t('600')}}</button>
			 {{$t('790')}}
			 <button type="button" class="btn btn-cgu" @click="$root.openCGVU($event, 2)" >{{$t('791')}}</button>.
		</div>

	</div>
</template>

<script src="./loginPage-component.js"></script>

