/**
 * INPUT DATE COMPONENT
 * check its content, and emit
 * --> 'isValid', true if is valid -->  this.$emit('isValid', true, this.name, this.inputValue)
 * --> 'isValid', false if is valid -->  this.$emit('isValid', false, this.name, this.inputValue)
 * @example <input-date-component v-if="input.type==='date'" :input="input" :index="index" v-on:isValid="isInputValid"></input-date-component>
*/


import {FormService} from 'salvum-utilities';
//import validationPictoComponent from  '../validation-picto/validation-picto-component.vue';
import Datepicker from 'vue3-datepicker'
import { fr } from "date-fns/locale";
export default {
    name: 'inputDateComponent',
    props: [
        'input', 'index', 'disabled'
    ],
    components : {
        //validationPictoComponent,
       Datepicker
    },
    emits: ['isValid'],
    data () {
        return {
            focus : false,
            inputValue:  this.input.value,
            alertDisplayed: false,
            className: '',
            hasIcon : this.input.icon !== undefined ? '' : 'no-icon',
            isValid : false,
            placeholder : this.$t('336'),
            options :{
                inputFormat : 'dd-MM-yyyy',
                initialView : this.input.value.length ? 'day' : 'year', // quand on ouvre, par jour ou par année
                openDate :  this.input.openDate || (this.input.value.length  ? this.input.value : new Date('January 01, 2000 00:01:00')) // quand on ouvre, dans les années 90
            } ,
            locale : fr
           
        }
    },
    
    mounted(){
        //this.validInput();
        console.log('date mounted', this.options, this.input, this.options.openDate, typeof(this.options.openDate))
        this.$el.querySelector('input').addEventListener('focusin',()=>this.onFocus(true)) 
        this.$el.querySelector('input').addEventListener('focusout',   ()=>this.onFocus(false))  
    },
    updated(){
        //console.log('date updated ', this.input)
    },
    destroyed(){
        this.$el.querySelector('input').removeEventListener('focusin',   this.onFocus(true))
        this.$el.querySelector('input').removeEventListener('focusout',   this.onFocus(false))
    },
    methods: {
        customPosition(){
            return {top: 80 , left: 0}
        } ,
        validInput() {
            console.log('valid date',  this.isValid, typeof(this.inputValue), this.inputValue.toString())
            this.isValid = FormService.dateIsValid(this.inputValue);
          
            //this.displayAlert(this.isValid, this.inputValue);
            this.$emit('isValid', this.isValid, this.input.name, this.inputValue, this.index);
        },

        displayAlert(bool, value) {
            this.alertDisplayed = bool ? undefined : this.input.alert;
            this.className = bool ? 'valid' : 'invalid';
            if (value.length === 0) {
                this.alertDisplayed = undefined;
                this.className = this.input.required ? 'invalid' : undefined;
            }
        },
        onFocus(bool){
            console.log('on date picker focus', bool)
            this.focus = bool;
            
        }
    }
}