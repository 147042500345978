<template lang="html">
  <div
    :class="'form-input form-input-date ' + className + ' ' + hasIcon + (inputValue.toString().length > 0 ? (isValid ? ' valid' : ' invalid') : '')">
    <font-awesome-icon v-if="input.icon !== undefined" class="head-picto" :icon="input.icon" />
    <div :class="'form-input-content ' + (focus ? ' focus' : ' no-focus')">
      <label :for="(index !== undefined ? 'form-' + index : undefined)"  v-if="(input.label !== undefined && input.label !== '')">{{ input.label }}</label>
      <p class="form-input-info" v-if="input.info !== undefined">{{ input.info }}</p>
      <p v-if="alertDisplayed" class="input-alert">{{ input.alert }}</p>
    
     
    <Datepicker v-model="inputValue" 
    :id="index !== undefined ? 'form-' + index : undefined"
    :locale="locale"
    :name="input.name" 
    :disabled="disabled" 
    :placeholder="placeholder"
    :required="input.required" 
    @update:modelValue="validInput"
    :input-format="options.inputFormat"
    :startingView="options.initialView"
    :startingViewDate="options.openDate"/>
    </div>
  </div>
</template>

<script src="./input-date-component.js"></script>

<style  src="./input-date-component.css"></style>

