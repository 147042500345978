<template lang="html">
  <form class="form-component" >
    <div class="form-inputs" v-for="(input, index) in form.inputs" >
      <input-text-component v-if="input.type==='text' && input.visible" :input="input" :index="index" v-on:isValid="isInputValid"></input-text-component>
      <input-password-component v-if="input.type==='password' && input.visible" :input="input" :index="index" v-on:isValid="isInputValid"></input-password-component>
      <input-email-component v-if="input.type==='email' && input.visible" :input="input" :index="index" v-on:isValid="isInputValid"></input-email-component>
      <input-checkbox-component v-if="input.type==='checkbox' && input.visible" :input="input" :index="index" v-on:isValid="isInputValid"></input-checkbox-component>
      <input-date-component v-if="input.type==='date' && input.visible" :input="input" :index="index"  v-on:isValid="isInputValid"></input-date-component>

      <div v-if="input.type==='phone' && input.visible"  :class="'form-input form-input-phone ' + (phoneObject.isValid ? 'valid' : '') + (!phoneObject.isValid && phoneObject.formattedNumber ? 'invalid' : '')" >
        <div :class="'form-input-content '+(focusPhone ? 'focus' : 'no-focus')">
       
        <label v-if=" input.label !== undefined && input.label !== ''">{{input.label}}<!--span class="input-required" v-if="input.required">*</span--></label>
        <!--VuePhoneNumberInput  @phone-number-focused="focusPhone = true"  @phone-number-blur="focusPhone = false" :class="{'valid' : phoneObject.isValid, 'invalid' : !phoneObject.isValid && phoneObject.formattedNumber}" v-model="input.value" :index="index"  :no-validator-state="true" :translations="phoneTranslations" :color="'#2D265C'" :border-radius="4" :error-color="'#E24912'" :valid-color="'#B23587'" :no-flags="true"  :only-countries="['FR']" :default-country-code="'FR'" :no-example="false" @update="onValidatePhone"/-->
				<MazPhoneNumberInput 
        v-model="input.value" 
        :index="index"  
        :class="{'valid' : phoneObject.isValid, 'invalid' : !phoneObject.isValid && phoneObject.formattedNumber}" 
        :no-validator-error="true" 
        :no-validator-success="true" 
        :translations="phone.translations" 
        :color="'#2D265C'" 
        :no-example="true"
        country-locale="fr-FR"
        country-code="FR"
        :only-countries="phone.onlyCountries"
        :placeholder="phone.placeholder"
        :error-color="'#E24912'" :valid-color="'#B23587'" 
        :no-flags="true"
        :no-search="true"   
        :show-code-on-list="true"
        @focus="onPhoneFocus(true)"
        @blur="onPhoneFocus(false)"
        @update="onValidatePhone"  />
      </div>
      </div>
       
     
    </div>
    <captcha-component v-if="form.hasCaptcha && form.isCaptchaVisible" :displayAlert="showCaptchaAlert" v-on:captchaValid="validCaptcha"></captcha-component>
    <ul class="form-buttons" v-if="form.buttons !== undefined || form.buttons.length >0">
      <li v-for="button in form.buttons">
        <button-component  v-show="button.visible" :disabled="button.disabled ? (form.mustDisableOnSubmit && isSubmitted ? true : !isAllValid ) : false" v-on:buttonClick="button.onclick !== undefined ? button.onclick() : onButtonClick(button.id)" :id="button.id" :class="'button form-button '+button.class" :type="button.type !== undefined ? button.type : 'button'" :icon="button.icon" :text="button.text"></button-component>
      </li>
    </ul>
    <p v-if="form.requiredLegend !== undefined" class="form-legend" ><span >*</span>{{form.requiredLegend}}</p>
  </form>
</template>

<script src="./form-component.js"></script>

<style scoped src="./form-component.css">

</style>

